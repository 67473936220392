import React from 'react';
import { DialogContent, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import { useManagers } from "services/hooks/rentals";

const AssignPropertyForm = ({property, handleManagerChange }) => {
    const {managers,isLoadingManager, error} = useManagers();

   

    return (
      <DialogContent>
        <Typography>Select a manger to assign to {property.rental_name}</Typography>
        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel>Manager</InputLabel>
          <Select
            name="related_manger"
            onChange={handleManagerChange}
            label="Related Landlord Rental"
          >
            {managers.map((manager) => (
              <MenuItem key={manager.id} value={manager.id}>
                {manager.related_user.first_name} {manager.related_user.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
    );
};

export default AssignPropertyForm;
