import React, { useState, useEffect } from "react";
import { Box, Checkbox, Button, Typography, List, ListItem, ListItemText } from "@mui/material";
import axios from "axios";
import { baseURL } from "services/API";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const RentalImagesReview = () => {
  const location = useLocation();
  const token = useSelector((state) => state.token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const id = useSelector((state) => state.property_id);

  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState({});

  // Fetch images for rental ID
  const fetchImages = async () => {
    try {
      const response = await axios.get(`${baseURL}/landlords/rentals/images?rental_id=${id}`);
      setImages(response.data.data);
    } catch (error) {
      console.error("Error fetching images", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  // Handle checkbox selection
  const handleCheckboxChange = (imageUrl) => (event) => {
    setSelectedImages({
      ...selectedImages,
      [imageUrl]: event.target.checked,
    });
  };

  // Handle submit action
  const handleSubmit = () => {
    const approvedImages = Object.keys(selectedImages).filter((image) => selectedImages[image]);
    console.log("Approved images:", approvedImages);
    // Submit approved images or perform further actions
  };

  return (
    <Box m="20px">
      <List>
        {images.map((imageData, index) => (
          <ListItem key={index} sx={{paddingLeft:5}}>
            <Checkbox
              checked={selectedImages[imageData.image] || false}
              onChange={handleCheckboxChange(imageData.image)}
            />
            <ListItemText
              primary={
                <Typography sx={{ fontSize: 16 }} component="div">
                  Image {index + 1}
                </Typography>
              }
              secondary={
                <Typography variant="body1" component="div" color="textSecondary" sx={{ fontSize: 16 }}>
                  URL: {imageData.image}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Box mt={4} sx={{ paddingLeft: 20 }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'gold', color: 'black', '&:hover': { backgroundColor: '#daa520' } }}
          onClick={handleSubmit}
        >
          Submit Reviewed Images
        </Button>
      </Box>
    </Box>
  );
};

export default RentalImagesReview;
