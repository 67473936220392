import React, { useState } from "react";
import axios from "axios";
import { baseURL } from "services/API";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Snackbar, MenuItem, Select, FormControl, InputLabel, Typography } from "@mui/material";
import { useLandlordExpenses } from "services/hooks/rentals";
import { setRefreshCount } from "state";
import ExpensesTable from "components/Tables/ExpensesTable";
import ExpenseForm from "components/Forms/LandlordExpensesForm";
import FormDialog from "components/modal";
import FlexBetween from "components/FlexBetween";
import BasicCard from "components/Card";
import { withoutSymbolFormatter } from "utilities/currencyFormatter";
import { useExpenseTypes } from "services/hooks/rentals";
import * as yup from 'yup';
import EditExpense from "components/ExpensesModals/EditExpense";

export default function LandlordExpenses({ landlord_id }) {
  const dispatch = useDispatch();
  const { landlordExpenses, isLoadingLandlordExpenses, errorLandlordExpenses } = useLandlordExpenses(landlord_id);
  const { expenseTypes } = useExpenseTypes();

  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [formData, setFormData] = React.useState({
    related_landlord_rental: "",
    amount: "",
    expense_category: "REPAIRS",
    description: "",
  });
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [filterType, setFilterType] = React.useState("expenseName");
  const [isSubmittingData, setIsSubmittingData] = React.useState(false);
  const [selectedExpense, setSelectedExpense] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [expense, setExpense] = useState({});
  const [errors, setErrors] = useState({});

  const expenseSchema = yup.object().shape({
    related_landlord_rental: yup.string().required('Please select rental property'),
    amount: yup.string().required('Please input an amount'),
    expense_category: yup.string().required('Please input an expense'),
    description: yup.string().required('Please input an description'),
  });

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleMenuClick = (event, expense) => {
    setAnchorEl(event.currentTarget);
    setSelectedExpense(expense);
  };

  const handleMenuClose = () => {
    setSelectedExpense({});
    setAnchorEl(null)
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
    setAnchorEl(null)
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedExpense({});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
    setAnchorEl(null)
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleDeleteExpense = async () => {
    try {
      const response = await axios.delete(
        `${baseURL}/admin/landlord_expenses/details/${selectedExpense.id}`
      );
      console.log(response.data);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("success");
      setMessage("Expense deleted successfully");

      dispatch(setRefreshCount());
    } catch (error) {
      console.error("Error deleting expense:", error);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("error");
      setMessage("Error deleting expense");
    }
    setDeleteDialogOpen(false);
  };

  const handleEditExpense = async () => {

    try {
      await expenseSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      const newError = {};
      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      });
      setErrors(newError);
      return;
    }

    try {
      const response = await axios.post(`${baseURL}/admin/landlord_expenses/details/${selectedExpense.id}`, expense);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("success");
      setMessage("Expense updated successfully");

      dispatch(setRefreshCount());
    } catch (error) {
      console.error("Error editing expense:", error);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("error");
      setMessage("Error editing expense");
    }
    setEditDialogOpen(false);
  };

  const handleInputChange = (event, fieldName) => {
    setExpense(expense => ({
      ...expense,
      [fieldName]: event.target.value,
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
    setFormData({
      related_landlord_rental: "",
      amount: "",
      expense_category: "",
      description: "",
    });
    setErrors({})
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (!toString(value).trim()) {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleRentalChange = (event) => {
    setFormData({ ...formData, related_landlord_rental: event.target.value });

  };

  const handleSubmit = async () => {
    // if (!formData.related_landlord_rental) {
    //   setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
    //   setSeverity("error");
    //   setMessage("Related landlord rental is required.");
    //   return;
    // }

    try {
      await expenseSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      const newError = {};
      error.inner.forEach((err) => { newError[err.path] = err.message;});
      setErrors(newError);
      return;
    }

    setIsSubmittingData(true);
    const request = axios.post(`${baseURL}/admin/landlord_expenses`, formData);

    request
      .then(() => {
        setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
        setSeverity("success");
        setMessage(selectedItem ? "Expense updated successfully" : "Expense added successfully");
        dispatch(setRefreshCount());
      })
      .catch((error) => {
        console.error("Error processing expense:", error);
        setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
        setSeverity("error");
        setMessage(selectedItem ? "Error updating expense" : "Error adding expense");
      })
      .finally(() => setIsSubmittingData(false));

    handleClose();
  };

  const filteredData = landlordExpenses.expenses.filter((item) => {
    const valueToFilter = item[filterType] || "";
    return valueToFilter.toLowerCase().includes(filterText.toLowerCase());
  });

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Box m="20px">
      <Box sx={{ p: 3 }}>
        <FlexBetween>
          <BasicCard
            name="Total Expense"
            value={withoutSymbolFormatter(parseFloat(landlordExpenses.total))}
          />

          {/* <BasicCard
            name="Total Expense Confirmed"
            value={withoutSymbolFormatter(parseFloat(50000))}
          /> */}
          <Button
            variant="contained"
            color="primary"
            sx={{ height: 56, width: 200 }}
            onClick={handleOpen}
          >
            Add Expense
          </Button>
        </FlexBetween>
      </Box>
      <Box sx={{ mt: 5 }}>
        <ExpensesTable
          data={paginatedData}
          handleInputChange={handleInputChange}
          handleMenuClick={handleMenuClick}
          page={currentPage}
          rowsPerPage={10}
          onPageChange={handlePageChange}
          anchorEl={anchorEl}
          selectedExpense={selectedExpense}
          handleDeleteExpense={handleDeleteExpense}
          handleMenuClose={handleMenuClose}
          handleDeleteDialogClose={handleDeleteDialogClose}
          handleDeleteDialogOpen={handleDeleteDialogOpen}
          handleEditExpense={handleEditExpense}
          landlordExpenses={landlordExpenses}
          handleEditDialogOpen={handleEditDialogOpen}
        />
      </Box>

      <Snackbar
        open={openSnack.open}
        autoHideDuration={6000}
        onClose={() => setOpenSnack({ ...openSnack, open: false })}
        message={message}
        severity={severity} // Apply severity for success or error
        action={
          <Button
            color="inherit"
            onClick={() => setOpenSnack({ ...openSnack, open: false })}
          >
            Close
          </Button>
        }
      />
      <FormDialog
        open={open}
        title={selectedItem ? "Edit Expense" : "Add Expense"}
        text="Fill in the details for the expense"
        content={
          <ExpenseForm
            formData={formData}
            handleFormChange={handleFormChange}
            errors={errors}
            expenseTypes={expenseTypes}
          />
        }
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isSubmittingData={isSubmittingData}
      />

      {selectedExpense.related_landlord_rental?
      <EditExpense
        open={editDialogOpen}
        title="Edit Landlord Expense Details"
        handleClose={handleEditDialogClose}
        handleSubmit={handleEditExpense}
        handleInputChange={handleInputChange}
        isSubmittingData={false}
        formData={selectedExpense}
        errors={errors}
        expenseTypes={expenseTypes}
      /> : null}

      <FormDialog
        title="Delete Expense"
        enableActions={true}
        open={deleteDialogOpen}
        content="Are you sure you want to delete this Expense?"
        handleClose={handleDeleteDialogClose}
        buttonText="Delete Expense"
        handleSubmit={handleDeleteExpense}
      />
    </Box>
  );
}
