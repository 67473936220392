import React, { useState } from "react";
import {Box, IconButton, InputBase, Typography, Select, MenuItem,  FormControl,Menu,  useTheme, useMediaQuery, Grid, Tooltip, Badge} from "@mui/material";
import {Search, Message, DarkMode, LightMode, Notifications, Help, Menu as MenuIcon, Close} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import { tokens } from "theme";

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const neutralLight = colors.primary[10];
  const dark = colors.grey[5];
  const background = colors.grey[5];
  const alt = colors.grey[5];
  const universalMedium = colors.primary[10];

  const fullName = `${user.first_name} ${user.last_name}`;

  const notiificationList = [];
  const [openNotifications, setOpenNotifications] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);
  const badgeContent = notiificationList.length;
  const handleClickOpen = (event) => {
    setAnchorE1(event.currentTarget);
    setOpenNotifications(true);
  };

  const handleClose = () => {
    setOpenNotifications(false);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        paddingBottom: 0,
        width: "100%",
      }}
    >
      <FlexBetween padding="0.5rem 10%" backgroundColor={alt}>
        <FlexBetween gap="1.75rem">
          <Typography
            fontWeight="bold"
            fontSize="clamp(1rem, 2rem, 2.25rem)"
            color="#FCB200"
            onClick={() => navigate("/home")}
            sx={{
              "&:hover": {
                color: universalMedium,
                cursor: "pointer",
              },
            }}
          >
            Rent Beta Admin
          </Typography>
          {/* {isNonMobileScreens && (
            <FlexBetween
              backgroundColor={neutralLight}
              borderRadius="9px"
              gap="3rem"
              padding="0.1rem 1.5rem"
            >
              <InputBase placeholder="Search..." />
              <IconButton>
                <Search />
              </IconButton>
            </FlexBetween>
          )} */}
        </FlexBetween>

        {/* DESKTOP NAV */}
        {isNonMobileScreens ? (
          <FlexBetween gap="2rem">
            {/* <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === "dark" ? (
                <DarkMode sx={{ fontSize: "25px" }} />
              ) : (
                <LightMode sx={{ color: universalMedium, fontSize: "25px" }} />
              )}
            </IconButton> */}
            {/* <Message sx={{ fontSize: "25px" }} /> */}
            <Grid item>
              <Tooltip title={`You have ${badgeContent} notifications`}>
                <IconButton onClick={handleClickOpen} anchorE1={anchorE1}>
                  <Badge badgeContent={badgeContent} color="primary">
                    <Notifications sx={{ fontSize: "25px", color: "white" }} />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Menu
                style={{ marginRight: "30px" }}
                anchorEl={anchorE1}
                open={openNotifications}
                onClose={handleClose}
              >
                {notiificationList.length === 0 ? (
                  <MenuItem>
                    <p>No Notifications Available</p>
                  </MenuItem>
                ) : (
                  notiificationList.map((row, index) => {
                    return (
                      <>
                        <MenuItem
                          style={{
                            color: "black",
                            margin: "5px 0 5px 0",
                            fontSize: "12px",
                            // minWidth: "200px",
                            maxWidth: "500px",
                            textWrap: "pretty",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          <span>
                            <p style={{ fontStyle: "italic" }}>{row.message}</p>{" "}
                            <p>{row.description}</p>{" "}
                          </span>
                        </MenuItem>
                        <hr />
                      </>
                    );
                  })
                )}
              </Menu>
            </Grid>
            {/* <Help sx={{ fontSize: "25px" }} /> */}
            <FormControl variant="standard" value={fullName}>
              <Select
                value={fullName}
                sx={{
                  backgroundColor: neutralLight,
                  width: "150px",
                  borderRadius: "0.25rem",
                  p: "0.25rem 1rem",
                  "& .MuiSvgIcon-root": {
                    pr: "0.25rem",
                    width: "3rem",
                  },
                  "& .MuiSelect-select:focus": {
                    backgroundColor: neutralLight,
                  },
                }}
                input={<InputBase />}
              >
                <MenuItem value={fullName}>
                  <Typography>{fullName}</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(setLogout());
                    navigate("/");
                  }}
                >
                  Log Out
                </MenuItem>
              </Select>
            </FormControl>
          </FlexBetween>
        ) : (
          <IconButton
            onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
          >
            <MenuIcon />
          </IconButton>
        )}

        {/* MOBILE NAV */}
        {!isNonMobileScreens && isMobileMenuToggled && (
          <Box
            position="fixed"
            right="0"
            bottom="0"
            height="100%"
            zIndex="10"
            maxWidth="500px"
            minWidth="300px"
            backgroundColor={background}
          >
            {/* CLOSE ICON */}
            <Box display="flex" justifyContent="flex-end" p="1rem">
              <IconButton
                onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
              >
                <Close sx={{ color:"white" }} />
              </IconButton>
            </Box>

            {/* MENU ITEMS */}
            <FlexBetween
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="3rem"
            >
              {/* <IconButton
                onClick={() => dispatch(setMode())}
                sx={{ fontSize: "25px" }}
              >
                {theme.palette.mode === "dark" ? (
                  <DarkMode sx={{ fontSize: "25px" }} />
                ) : (
                  <LightMode sx={{ color: dark, fontSize: "25px" }} />
                )}
              </IconButton> */}
              {/* <Message sx={{ fontSize: "25px" }} /> */}
              <Grid item>
                <Tooltip title={`You have ${badgeContent} notifications`}>
                  <IconButton onClick={handleClickOpen} anchorE1={anchorE1}>
                    <Badge badgeContent={badgeContent} color="primary">
                      <Notifications
                        sx={{ fontSize: "25px", color: "white" }}
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Menu
                  style={{ marginRight: "30px" }}
                  anchorEl={anchorE1}
                  open={openNotifications}
                  onClose={handleClose}
                >
                  {notiificationList.length === 0 ? (
                    <MenuItem>
                      <p>No Notifications Available</p>
                    </MenuItem>
                  ) : (
                    notiificationList.map((row, index) => {
                      return (
                        <>
                          <MenuItem
                            style={{
                              color: "black",
                              margin: "5px 0 5px 0",
                              fontSize: "12px",
                              // minWidth: "200px",
                              maxWidth: "500px",
                              textWrap: "pretty",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            <span>
                              <p style={{ fontStyle: "italic" }}>
                                {row.message}
                              </p>{" "}
                              <p>{row.description}</p>{" "}
                            </span>
                          </MenuItem>
                          <hr />
                        </>
                      );
                    })
                  )}
                </Menu>
              </Grid>
              {/* <Help sx={{ fontSize: "25px" }} /> */}
              <FormControl variant="standard" value={fullName}>
                <Select
                  value={fullName}
                  sx={{
                    backgroundColor: neutralLight,
                    width: "150px",
                    borderRadius: "0.25rem",
                    p: "0.25rem 1rem",
                    "& .MuiSvgIcon-root": {
                      pr: "0.25rem",
                      width: "3rem",
                    },
                    "& .MuiSelect-select:focus": {
                      backgroundColor: neutralLight,
                    },
                  }}
                  input={<InputBase />}
                >
                  <MenuItem value={fullName}>
                    <Typography>{fullName}</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => dispatch(setLogout())}>
                    Log Out
                  </MenuItem>
                </Select>
              </FormControl>
            </FlexBetween>
          </Box>
        )}
      </FlexBetween>
    </Box>
  );
};

export default Navbar;
