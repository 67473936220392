import React from "react";
import { Box, Typography, Grid2, Avatar, IconButton, DialogContentText, Divider } from "@mui/material";
import CustomTab from "components/Tabs/Tab";
import { FlexStart } from "components/FlexBox/FlexBox";
import LandlordPaymentDetails from "./LandlordPaymentDetails";
import LandlordExpenses from "scenes/GeneralLandlords/LandlordExpenses";
import LandlordTenants from "./LandlordTenants";
import LandlordProperties from "./LandLordProperties";
import { useLocation } from "react-router-dom";
import FlexBetween from "components/FlexBetween";




const LandLordDetailsTab = () => {
    const location = useLocation();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [profilePicture, setProfilePicture] = React.useState(location.state.related_user.profile_picture);
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfilePicture(reader.result); // Update profile picture state
        };
        reader.readAsDataURL(file);
      }
    };
    const landlordName = location.state.related_user.first_name+' '+location.state.related_user.last_name;
    const landLordContact = location.state.related_user.phone_number;
    const email = location.state.related_user.email;
    const businessName = location.state.business_name;
    const payDay = location.state.pay_day;
    const payPercentage = location.state.pay_percentage;

    const tabComponents = [
      { Component: <LandlordPaymentDetails />, label: "Payments", index: 0 },
      { Component: <LandlordExpenses  landlord_id={location.state.id}/>, label: "Expenses", index: 1 },
      { Component: <LandlordProperties />, label: "Properties", index: 2 },
      { Component: <LandlordTenants />, label: "Tenants", index: 3 },
    ]; 


    return (
        <Box>
            <Grid2 container spacing={4} sx={{ alignItems:"baseline", marginBottom:"0" }}>
                <Grid2 xs={2}>
                    <Box display="flex" alignItems="center" flexDirection="column" sx={{ margin: "0.5rem 0 0 2rem" }}>
                        <Avatar
                        alt="User Profile Picture"
                        src={profilePicture}
                        sx={{ width: 75, height: 75 }}
                        />
                        <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        >
                        <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                        </IconButton>
                    </Box>
                </Grid2>

                <Grid2 xs={8}>
                    <FlexBetween sx={{ margin:"2rem  0 0 0.5rem"}}>
                        <Box sx={{ display:"flex", marginRight:"15px", flexDirection:"row", alignItems:"baseline" }}>
                            <Typography fontWeight="bold" variant="h5">Landlord: </Typography>
                            <Typography variant="h6"> {landlordName}</Typography>
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Box sx={{ display:"flex", marginRight:"15px", flexDirection:"row", alignItems:"baseline" }}>
                            <Typography fontWeight="bold" variant="h5">Contact: </Typography>
                            <Typography variant="h6"> {landLordContact}</Typography>
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Box sx={{ display:"flex", marginRight:"15px", flexDirection:"row", alignItems:"baseline" }}>
                            <Typography fontWeight="bold" variant="h5">Business Name: </Typography>
                            <Typography variant="h6"> {businessName}</Typography>
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Box sx={{ display:"flex", marginRight:"15px", flexDirection:"row", alignItems:"baseline" }}>
                            <Typography fontWeight="bold" variant="h5">Pay Day: </Typography>
                            <Typography variant="h6"> {payDay}</Typography>
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Box sx={{ display:"flex", marginRight:"15px", flexDirection:"row", alignItems:"baseline" }}>
                            <Typography fontWeight="bold" variant="h5">Pay Percentage: </Typography>
                            <Typography variant="h6"> {payPercentage}%</Typography>
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Box sx={{ display:"flex", marginRight:"15px", flexDirection:"row", alignItems:"baseline" }}>
                            <Typography fontWeight="bold" variant="h5">Email: </Typography>
                            <Typography variant="h6"> {email}</Typography>
                        </Box>
                        
                    </FlexBetween>
                </Grid2>

                <Grid2>

                </Grid2>

            </Grid2>
            
            <hr/>
            <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/> 
        </Box>
    )

}

export default LandLordDetailsTab;