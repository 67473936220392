import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const EditLandlordForm = ({ newLandlord, handleChange, validationErrors }) => {
  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            label="First Name"
            type="text"
            fullWidth
            variant="outlined"
            name="first_name"
            value={newLandlord.first_name}
            onChange={handleChange}
            error={Boolean(validationErrors.first_name)}
            helperText={validationErrors.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            label="Last Name"
            type="text"
            fullWidth
            variant="outlined"
            name="last_name"
            value={newLandlord.last_name}
            onChange={handleChange}
            error={Boolean(validationErrors.last_name)}
            helperText={validationErrors.last_name}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Business Name"
            type="text"
            fullWidth
            variant="outlined"
            name="business_name"
            value={newLandlord.business_name}
            onChange={handleChange}
            error={Boolean(validationErrors.business_name)}
            helperText={validationErrors.business_name}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            label="Landlord Contact"
            type="text"
            fullWidth
            variant="outlined"
            name="phone_number"
            value={newLandlord.phone_number}
            onChange={handleChange}
            error={Boolean(validationErrors.phone_number)}
            helperText={validationErrors.phone_number}
          />
        </Grid>

        {/* Row 2 */}

        {/* Row 3 */}
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            label="Email"
            type="text"
            fullWidth
            variant="outlined"
            name="email"
            value={newLandlord.email}
            onChange={handleChange}
            error={Boolean(validationErrors.email)}
            helperText={validationErrors.email}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            disabled
            variant="outlined"
            name="username"
            value={newLandlord.username}
            onChange={handleChange}
            error={Boolean(validationErrors.username)}
            helperText={validationErrors.username}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            label="Password"
            type="text"
            fullWidth
            
            variant="outlined"
            name="password"
            value={newLandlord.password}
            onChange={handleChange}
            error={Boolean(validationErrors.password)}
            helperText={validationErrors.password}
          />
        </Grid> */}

        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={"bold"}>
            Payment Terms
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            label="Payment Percentage"
            type="number"
            fullWidth
            variant="outlined"
            name="pay_percentage"
            value={newLandlord.pay_percentage}
            onChange={handleChange}
            error={Boolean(validationErrors.pay_percentage)}
            helperText={validationErrors.pay_percentage}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            label="Payment Date"
            type="text"
            fullWidth
            variant="outlined"
            name="pay_day"
            value={newLandlord.pay_day}
            onChange={handleChange}
            error={Boolean(validationErrors.pay_day)}
            helperText={validationErrors.pay_day}
          />
        </Grid>

        {/* Row 4 */}
      </Grid>
    </Box>
  );
};

export default EditLandlordForm;
