import React, {useEffect, useState} from 'react';
import { Box, Typography } from "@mui/material";
import {useDropzone} from 'react-dropzone';
import Grid from "@mui/material/Grid";
import { FlexBetween, FlexStart } from 'components/FlexBox/FlexBox';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


const ImageUploader = ({files, setFiles, maxFiles}) => {
  const {getRootProps, getInputProps, acceptedFiles, fileRejections} = useDropzone({
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg']
    },
    maxFiles: maxFiles,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const acceptedFileItems = acceptedFiles.map(file => (
    <>  
    {file.size/1000000 < 0.01 ? (
        <li key={file.path}>
          {file.path} - {file.size/1000} KB
        </li>
     ) : (
        <li key={file.path}>
          {file.path} - {file.size/1000000} MB
        </li>
    )}
        
    </>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
   return (
     <li key={file.path}>
          {file.path} - {file.size/1000000} MB
          <ul>
            {errors.map(e => <li key={e.code}>{e.message}</li>)}
         </ul>

     </li>
   ) 
  });
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview)}}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <Box sx={{
            border: "2px dashed black",
            padding: "20px"
        }}>
            <input {...getInputProps()} />
            <Typography>Drag and drop some files here, or click to select files.Please ensure to select all the files at once. Please select a minimum of 1 and a maximum of 3</Typography>
        </Box>
      </div>
      <Grid style={thumbsContainer}>
        <FlexStart>
            {thumbs}
        </FlexStart>

        <Grid>
            {acceptedFiles.length > 0 && (
                <Grid>
                    <Typography variant="h4" sx={{color:"#0759e8", fontSize: 15}}>Accepted files</Typography>
                    <ul>{acceptedFileItems}</ul>
                </Grid>
            )}
        </Grid>
        <Grid>
            {fileRejectionItems.length > 0 && (
                <Grid>
                    <Typography variant="h4" sx={{color:"#e80707", fontSize: 15}}>Rejected files</Typography>
                    <ul>{fileRejectionItems}</ul>
                </Grid>
            )}
        </Grid>
      </Grid>
    </section>
  );
}

export default ImageUploader;