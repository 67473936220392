import React from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "state";
import CustomTab from "components/Tabs/Tab";
import Rentals from "scenes/GeneralProperties/Properties";
import RegisteredProperties from "scenes/Management/Managers/registeredProperties";
import UnassignedProperties from "./UnassignedProperties";



const GeneralPropertiesTab = () => {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['generalPropertiesTab']) );
    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setCurrentTab({ name: "generalPropertiesTab", tab: newValue }));
    };

    const tabComponents = [
      { Component: <Rentals />, label: "All Properties", index: 0 },
      { Component: <UnassignedProperties/>, label: "Unassigned Properties", index: 1 },
      { Component: <RegisteredProperties/>, label: "Registered Properties", index: 2 },
    ]; 


    return (
        <Box>
            <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/> 
        </Box>
    )

}

export default GeneralPropertiesTab;