import React, { useState } from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter, TablePagination, Typography, Box, IconButton, Snackbar, Alert, Divider} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import axios from "axios";
import { baseURL } from "services/API";
import EditLandlorPayments from "components/LandlordPaymentsModal/EditLandlordPayments";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import FormDialog from "components/modal";

const LandlordDetailsTable = ({ landlordPayments, isLoadingLandlordPayments,handleEditDialogOpen, setSelectedLandlordPayment,errorLandlordPayments }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [landlordPaymentDetails, setLandlordPaymentDetails] = useState({})
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = openSnack;

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  if (isLoadingLandlordPayments) {
    return <Typography>Loading...</Typography>;
  }

  if (errorLandlordPayments) {
    return <Typography>Error loading payments.</Typography>;
  }

  const handleMenuClick = (event, landlordPayment) => {
    setAnchorEl(event.currentTarget);
    setSelectedLandlordPayment(landlordPayment);
    
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleMenuClose = () => {
    setSelectedLandlordPayment(null);
    setAnchorEl(null);
  };
 
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handleDeleteDialogOpen = () => {
    // setDeleteDialogOpen(true);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedLandlordPayment((prevState) => ({
      ...prevState,
      [name]: value, 
    }));
  };
  

  // const handleEditLandlordPayments = async () => {
  //   try {
  //     const response = await axios.post(`${baseURL}/admin/landlord_payments/details/${selectedLandlordPayment.id}`, landlordPaymentDetails );
  //     console.log("Payment edited successfully:", response.data);
  
  //     setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
  //     setSeverity("success");
  //     setMessage("Payment edited successfully");
  //     setEditDialogOpen(false);
  //     setNewItemData();
  //   } catch (error) {
  //     console.error("Error editing payment:", error);
  
  //     setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
  //     setSeverity("error");
  //     setMessage("Error editing payment");
  //   }
  //   handleMenuClose();
  // };
  

  // const handleDeleteLandlordPayment = async () => {
  //   try {
  //     const response = await axios.delete(`${baseURL}/admin/landlord_payments/details/${selectedLandlordPayment.id}`);

  //     setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
  //     setSeverity("success");
  //     setMessage("Payment deleted successfully");
  //     setNewItemData();
  //   } catch (error) {
  //     console.error("Error deleting payment:", error);

  //     setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
  //     setSeverity("error");
  //     setMessage("Error deleting payment");
  //   }
  //   setDeleteDialogOpen(false);
  // };

  const handleCloseSnackbar = () => {
    setOpenSnack({ ...openSnack, open: false });
  };

  return (
    <>
      {landlordPayments.payments.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ fontSize: "16px"}}>Business Name</StyledTableCell>
                  <StyledTableCell sx={{ fontSize: "16px"}}>Rental Name</StyledTableCell>
                  <StyledTableCell sx={{ fontSize: "16px"}}>Month Paid</StyledTableCell>
                  <StyledTableCell sx={{ fontSize: "16px"}}>Date Paid</StyledTableCell>
                  <StyledTableCell align="right" sx={{ fontSize: "16px" }}>Amount</StyledTableCell>
                  <StyledTableCell align="right" sx={{ fontSize: "16px" }}>Added By</StyledTableCell>
                  <StyledTableCell align="right" sx={{ fontSize: "16px"}}>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? landlordPayments.payments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : landlordPayments.payments
                ).map((landlordPayment) => (
                  <StyledTableRow
                    key={landlordPayment.id}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <StyledTableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.related_landlord_rental.related_landlord.business_name}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.related_landlord_rental.rental_name}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.related_month.month} {landlordPayment.related_month.year}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.date_paid}
                    </StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.amount}
                    </StyledTableCell>
                    <StyledTableCell align="right" sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.added_by.first_name} {landlordPayment.added_by.last_name}
                    </StyledTableCell>
                    <StyledTableCell align="right" sx={{ padding: "16px" }}>
                      <IconButton onClick={(event) => handleMenuClick(event, landlordPayment)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleEditDialogOpen}>Edit</MenuItem>
                        <Divider/>
                        <MenuItem onClick={handleDeleteDialogOpen} style={{ color: 'red' }}>Delete</MenuItem>
                      </Menu>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={7}
                    count={landlordPayments.payments.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                    ActionsComponent={TablePaginationActions}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ fontSize: "16px"}}>Business Name</StyledTableCell>
                  <StyledTableCell sx={{ fontSize: "16px"}}>Rental Name</StyledTableCell>
                  <StyledTableCell sx={{ fontSize: "16px"}}>Month Paid</StyledTableCell>
                  <StyledTableCell sx={{ fontSize: "16px"}}>Date Paid</StyledTableCell>
                  <StyledTableCell align="right" sx={{ fontSize: "16px" }}>Amount</StyledTableCell>
                  <StyledTableCell align="right" sx={{ fontSize: "16px" }}>Added By</StyledTableCell>
                  <StyledTableCell align="right" sx={{ fontSize: "16px"}}>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Typography>No payments found for the landlord.</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity}>
          {message}
        </Alert>
      </Snackbar>

      {/* <EditLandlorPayments
        open={editDialogOpen}
        title="Edit Landlord Payment Details"
        handleClose={handleEditDialogClose}
        handleSubmit={handleEditLandlordPayments}
        handleInputChange={handleInputChange}
        isSubmittingData={false}
        landlord_name={`${selectedLandlordPayment?.related_landlord_rental.related_landlord.related_user.first_name} ${selectedLandlordPayment?.related_landlord_rental.related_landlord.related_user.last_name}`}
        description={selectedLandlordPayment?.description}
        business_name={selectedLandlordPayment?.related_landlord_rental.related_landlord.business_name}
        rental_name={selectedLandlordPayment?.related_landlord_rental.rental_name}
        date_paid={selectedLandlordPayment?.date_paid || ""}
        month_year={`${selectedLandlordPayment?.related_month?.month || ""} ${selectedLandlordPayment?.related_month?.year || ""}`}
        amount={selectedLandlordPayment?.amount || ""}
        paid_by={`${selectedLandlordPayment?.added_by.first_name} ${selectedLandlordPayment?.added_by.last_name}`}
      /> */}

      {/* <FormDialog
        title="Delete Payment"
        enableActions={true}
        open={deleteDialogOpen}
        content="Are you sure you want to delete this Payment?"
        handleClose={handleDeleteDialogClose}
        buttonText="Delete Payment"
        handleSubmit={handleDeleteLandlordPayment}
      /> */}

    </>
  );
};

export default LandlordDetailsTable;
