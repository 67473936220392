import React from "react";
import { Box, Typography, Paper, Stack} from "@mui/material";
import RegisteredPropertiesTable from "components/Tables/registeredProperties";
import { useRegisteredProperties} from "services/hooks/rentals";

export default function RegisteredProperties() {

  // Fetch contact Requests
  const { registeredProperties, isLoadingRegisteredProperties, errorRegisteredProperties } = useRegisteredProperties();


  return (
    <Box m="20px">
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
        >
          Registered Properties
        </Typography>
      </Stack>
      <Paper sx={{ marginTop: "1rem", overflow: "hidden" }}>
        <RegisteredPropertiesTable
          registeredProperties={registeredProperties}
          isLoadingRegisteredProperties={isLoadingRegisteredProperties}
          errorRegisteredProperties={errorRegisteredProperties}
        />
      </Paper>
    </Box>
  );
}
