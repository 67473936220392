import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ListIcon from "@mui/icons-material/List"; // Importing the icon for listing
import CoPresentIcon from "@mui/icons-material/CoPresent";

export default function UnassgnedUnitsPopover({ popperId, openPopper, anchorEl, handleClosePopOver, handleClickOpen, navigate, activeUnit, handleModalView }) {
	const handleAction = (modalview) => {
		handleModalView(modalview);
		handleClickOpen();
		handleClosePopOver();
	};
	
	return (
		<div style={{ zIndex: 20000 }}>
			<Popover
				id={popperId}
				open={openPopper}
				anchorEl={anchorEl}
				onClose={handleClosePopOver}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				<Stack sx={{ padding: "10px", alignContent: "left" }}>
					{/* View Unit Details Button */}
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => navigate("/units/details", { state: { id: activeUnit } })}>
						<RemoveRedEyeIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
						View Unit Details
					</Button>
					<Divider />

					{/* New Button for Listing Units */}
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction('addTenant')}>
						<CoPresentIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
						ASSIGN TENANT
					</Button>
					<Divider />

					{/* Delete Unit Button */}
					<Button sx={{ color: "red", padding: "10px" }} onClick={() => handleAction("delete")}>
						Delete Unit
					</Button>
				</Stack>
			</Popover>
		</div>
	);
}
