import * as React from "react";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from "@mui/icons-material/Message";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PopoverButton from "components/PopoverButton";

export default function LandlordsPopover({
  popperId,
  openPopper,
  anchorEl,
  handleClosePopOver,
  handleClickOpen,
  selectedLandlord,
  setSelectedLandlord,
  handleModalView,
  handleViewLandlord
}) {
  const handleAction = (modalview) => {
    if (modalview==='edit'){
      setSelectedLandlord({
        id: selectedLandlord.id,
        phone_number: `0${selectedLandlord.related_user.phone_number}`,
        first_name: selectedLandlord.related_user.first_name,
        username: selectedLandlord.related_user.username,
        last_name: selectedLandlord.related_user.last_name,
        email: selectedLandlord.related_user.email,
        business_name: selectedLandlord.business_name,
        pay_percentage: selectedLandlord.pay_percentage,
        pay_day: selectedLandlord.pay_day,
      });
    }
    handleModalView(modalview);
    handleClickOpen();
  };

  return (
    <div >
      <Popover
        id={popperId}
        open={openPopper}
        anchorEl={anchorEl}
        onClose={handleClosePopOver}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Stack sx={{ padding: "10px", alignContent: "left" }}>

          <PopoverButton name={"View Details"} icon={<VisibilityIcon/>} onClick={()=>handleViewLandlord()} />

          <PopoverButton name={"Edit Details"} icon={<EditIcon/>} onClick={()=>handleAction('edit')} />

          <PopoverButton name={"Send Message"} icon={<MessageIcon/>} onClick={()=>handleAction('message')} />

          <PopoverButton name={"Delete"} icon={<DeleteIcon/>} onClick={()=>handleAction('delete')} />
        </Stack>
      </Popover>
    </div>
  );
}