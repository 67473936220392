import React, {useState} from "react";
import { Grid, Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { formatDate } from "utilities/date";
import FormDialog from "components/modal";
import SettingsPill from "components/SettingsPill";
import { Divider } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { useNavigate } from "react-router-dom";

export default function RegistrationConfirmation() {
    const navigate = useNavigate();
	const [openPassword, setOpenPassword] = useState(false);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
    const [openArchiveData, setOpenArchiveData] = useState(false);
    const [openChangeVisibility, setOpenChangeVisibility] = useState(false);
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
	const [details, setDetails] = useState("");
	const [modal, setModal] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

    const handleClickOpenPassword = () => {
		setOpenPassword(true);
	};

	const handleClosePassword = () => {
		setModal("");
		setOpenPassword(false);
	};

    const handleClickOpenDeleteAccount = () => {
		setOpenDeleteAccount(true);
	};

	const handleCloseDeleteAccount = () => {
		setModal("");
		setOpenDeleteAccount(false);
	};

    const handleClickOpenArchiveData = () => {
		setOpenArchiveData(true);
	};

	const handleCloseArchiveData = () => {
		setModal("");
		setOpenArchiveData(false);
	};

    const handleClickOpenChangeVisibility = () => {
		setOpenChangeVisibility(true);
	};

	const handleCloseChangeVisibility = () => {
		setModal("");
		setOpenChangeVisibility(false);
	};

    const handleChangeNewPassword = (event) => {
		setNewPassword(event.target.value);
	};

	const handleChangeConfirmPassword = (event) => {
		setConfirmPassword(event.target.value);
	};

    const handleSubmitChangePassword = () => {
        
    }


	return (
    <Box m="20px">
      <Breadcrumbs>
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/landlords")}
        >
          <BusinessIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          <Typography>Landlord</Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Registered Landlord Documents</b>
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>Landlord Documents</h1>
          <h3>RENT COLLECTION SERVICE AGREEMENT</h3>
          <Box sx={{ border: "1px solid black", padding: "10px" }}>
            <SettingsPill
              pillTitle={"TERMS OF BUSINESS"}
              pillDescription={
                "Review the Signed working terms of business document"
              }
              buttonText={"View Document"}
              buttonAction={handleClickOpenPassword}
            />
            <Divider />
          </Box>

          <h3>NATIONAL IDENTITY CARD</h3>
          <Box sx={{ border: "1px solid black", padding: "10px" }}>
            <SettingsPill
              pillTitle={"Front of ID"}
              pillDescription={
                "Review the a photo of the FRONT part of your National ID"
              }
              buttonText={"Review ID"}
            />
            <Divider />
            <SettingsPill
              pillTitle={"Back of ID"}
              pillDescription={
                "Review the a photo of the FRONT part of your National ID"
              }
              buttonText={"Review ID"}
            />
            <Divider />
          </Box>
        </Grid>
      </Grid>
      <FormDialog
        open={openPassword}
        handleClose={handleClosePassword}
        title={"Change your Password"}
        handleSubmit={handleSubmitChangePassword}
        buttonText="Confirm Change Password"
        enableActions={true}
        buttonClass={"add-button"}
        content={<></>}
      />

      <FormDialog
        open={openChangeVisibility}
        handleClose={handleCloseChangeVisibility}
        title={
          "Are you sure you want to hide your account data from RentBeta Admins?"
        }
        handleSubmit={handleSubmitChangePassword}
        buttonText="Confirm Hide Account"
        enableActions={true}
        buttonClass={"delete-button"}
        content={<></>}
      />

      <FormDialog
        open={openArchiveData}
        handleClose={handleCloseArchiveData}
        title={"Are you sure you want to archive your data?"}
        handleSubmit={handleSubmitChangePassword}
        buttonText="Confirm Archive Account Data"
        enableActions={true}
        buttonClass={"delete-button"}
        content={<></>}
      />

      <FormDialog
        open={openDeleteAccount}
        handleClose={handleCloseDeleteAccount}
        title={"Are you sure you want to delete your account?"}
        handleSubmit={handleSubmitChangePassword}
        buttonText="Confirm Delete Account"
        enableActions={true}
        buttonClass={"delete-button"}
        content={<></>}
      />
    </Box>
  );
}
