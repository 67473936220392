import React, { useState } from "react";
import axios from "axios";
import { Box, Button, Typography, Paper, Stack, Snackbar, Alert } from "@mui/material";
import RegisteredLandlordsTable from "components/Tables/RegisteredLandlords";
import { useReferredLandlords } from "services/hooks/rentals";

export default function RegisteredLandlords() {
 

  // Use custom hook to fetch referred landlords data
  const { referredLandlords, isLoadingReferredLandlords, errorReferredLandlords } = useReferredLandlords();
 

  return (
    <Box m="20px">
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
        >
          Newly Registered Landlords
        </Typography>
      </Stack>
      <Paper sx={{ marginTop: "1rem", overflow: "hidden" }}>
        <RegisteredLandlordsTable
         referredLandlords={referredLandlords}
        />
      </Paper>
    </Box>
  );
}
