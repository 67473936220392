import React, {useEffect, useState} from "react";
import { Box, Typography, Divider } from "@mui/material";
import CustomTab from "components/Tabs/Tab";
import { FlexCenter, FlexEnd } from "components/FlexBox/FlexBox";
import { useLocation } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import PropertyDetails from "./propertyDetails";
import axios from "axios";
import { baseURL } from "services/API";
import HouseIcon from "@mui/icons-material/House";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import NearMeIcon from "@mui/icons-material/NearMe";
import PlaceIcon from "@mui/icons-material/Place";
import UnitsTable from "components/Tables/UnitsTable";
import Tickets from "scenes/Tickets";
import UnassignedUnitsTable from "components/Tables/UnassignedUnitsTable";
import PropertyDashboard from "./propertyDashBoard";
import { useSelector } from "react-redux";
import { setCurrentTab } from "state";
import { useDispatch } from "react-redux";
import PropertyTenants from "./propertyTenants";
import PropertyManagers from "./propertyManagers";

const facilityIcons = {
  marginRight: "8px",
  fontSize: "20px",
  color: "#52524e",
};

const coloredIconGreen = {
  marginRight: "8px",
  fontSize: "20px",
  color: "#0af047",
};

const coloredIconRed = {
  marginRight: "8px",
  fontSize: "20px",
  color: "#f20a0a",
};

const detailsText = {
  fontSize: "16px",
  fontWeight: 600,
  padding: "5px",
};

const descriptionText = {
  fontSize: "15px",
  fontWeight: 400,
  fontStyle: "italic",
};




const PropertyDetailsTab = () => {
    const dispatch = useDispatch();
    const id = useSelector((state) => state.property_id);
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
      dispatch(setCurrentTab({ name: "prropertyTab", tab: newValue }));
    };
    
    const [propertyDetails, setPropertyDetails] = useState({});
    const [rentalName, setRentalName] = useState("");
    const [propertyUse, setPropertyUse] = useState("");
    const [propertyLocation, setPropertyLocation] = useState("");
    const [locationCounty, setLocationCounty] = useState("");
    const [security, setSecurity] = useState("");
    const [hospitalAccess, setHospitalAccess] = useState("");
    const [roadAccess, setRoadAccess] = useState("");
    const [parking, setParking] = useState("");
    const [fibre, setFibre] = useState("");
    const [elevator, setElevator] = useState("");
    const [gym, setGym] = useState("");
    const [pool, setPool] = useState("");

    const fetchDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/landlords/rentals/details?rental_id=${id}`
        );
        setPropertyDetails(response.data.data);
        setRentalName(response.data.data.rental_name);
        setPropertyUse(response.data.data.rental_type);
        setPropertyLocation(response.data.data.location);
        setLocationCounty(response.data.data.location_county);
        setSecurity(response.data.data.has24_security);
        setHospitalAccess(response.data.data.hospital_access);
        setRoadAccess(response.data.data.main_road_access);
        setParking(response.data.data.has_parking);
        setFibre(response.data.data.has_fibre);
        setElevator(response.data.data.has_elevator);
        setGym(response.data.data.has_gym);
        setPool(response.data.data.has_swimming_pool);
      } catch (e) {
        setPropertyDetails(true);
      }
    };

    useEffect(()=>{
        fetchDetails()
    }, [])


    const tabComponents = [
      { Component: <PropertyDashboard handleChange={handleChange}/>, label: "Property DashBoard", index: 0 },
      { Component: <PropertyDetails/>, label: "Property Details", index: 1 },
      { Component: <UnitsTable/>, label: "Units", index: 2 },
      { Component: <PropertyTenants property_id={id}/>, label: "Current Tenants", index: 3 },
      { Component: <UnassignedUnitsTable/>, label: "Unoccupied Units", index: 4 },
      { Component: <></>, label: "Tickets", index: 5 },
      { Component: <PropertyManagers/>, label: "Managers Attached", index: 6 },
    ]; 


    return (
      <Box>
        <Box sx={{ alignItems: "baseline", marginBottom: "0", width:"80%", margin:"0 0 0 0.5rem" }}>
            <FlexBetween sx={{ margin: "0.7rem  0 0 0.5rem" }}>
              <FlexCenter sx={{ margin: "0.5rem" }}>
                <HouseIcon sx={facilityIcons} />
                <Typography sx={descriptionText}>Property Name: </Typography>
                <Typography sx={detailsText}>{rentalName}</Typography>
              </FlexCenter>
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexCenter sx={{ margin: "0.5rem" }}>
                <FormatPaintIcon sx={facilityIcons} />
                <Typography sx={descriptionText}>Property Use:</Typography>
                <Typography sx={detailsText}>{propertyUse}</Typography>
              </FlexCenter>
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexCenter sx={{ margin: "0.5rem" }}>
                <NearMeIcon sx={facilityIcons} />
                <Typography sx={descriptionText}>Property Location:</Typography>
                <Typography sx={detailsText}>{propertyLocation}</Typography>
              </FlexCenter>
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexCenter sx={{ margin: "0.5rem" }}>
                <PlaceIcon sx={facilityIcons} />
                <Typography sx={descriptionText}>Location County:</Typography>
                <Typography sx={detailsText}>{locationCounty}</Typography>
              </FlexCenter>
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexCenter sx={{ margin: "0.5rem" }}>
                <Typography sx={descriptionText}>Landlord:</Typography>
                {/* <Typography sx={detailsText}>{landlordName}</Typography> */}
              </FlexCenter>
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexCenter sx={{ margin: "0.5rem" }}>
                <Typography sx={descriptionText}>Contact:</Typography>
                {/* <Typography sx={detailsText}>{landLordContact}</Typography> */}
              </FlexCenter>
              <Divider orientation="vertical" variant="middle" flexItem />
              
            </FlexBetween>
        </Box>

        <Divider />
        <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
      </Box>
    );

}

export default PropertyDetailsTab;