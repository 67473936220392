import * as React from "react";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";  // Import the icon
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UnitsPopover from "components/PopOvers/UnitsPopover"; // Import UnitsPopover
import { useLocation, useNavigate } from "react-router-dom";
import { useRentalUnits, useUnassignedRentalUnits } from "services/hooks/rentals";
import { useSelector } from "react-redux";
import UnassgnedUnitsPopover from "components/PopOvers/UnassignedUnitsPopover";
import AddTenantsFromUnoccupiedForm from "components/Forms/AddTenantsFromUnoccupiedListForm";
import moment from "moment";
import FormDialog from "components/modal";
import * as yup from 'yup';
import { baseURL } from "services/API";
import axios from "axios";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";

export default function UnassignedUnitsTable() {
  const id = useSelector((state) => state.property_id);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null); // For the popover
  const [activeUnit, setActiveUnit] = React.useState(null); // Store active unit for popover
  const openPopper = Boolean(anchorEl);
  const popperId = openPopper ? "simple-popover" : undefined;
  const [modal, setModal] = React.useState("");
  const [details, setDetails] = React.useState("");
	const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [severity, setSeverity] = React.useState("");
	const [isLoadedUnits, setIsLoadedUnits] = React.useState(false);
	const [tenantDetails, setTenantDetails] = React.useState({});
	const [loadedTenant, setLoadedTenant] = React.useState(false);
	const [loadingTenantsError, setLoadingTenantsError] = React.useState("");
	const [isExistingTenant, setIsExisitingTenant] = React.useState(true);
	const [loadingTenantDetails, setLoadingTenantDetails] = React.useState(true);
  const [errorLoadingTenantDetails, setErrorLoadingTenantDetails] = React.useState(false);
	const [newTenantDetails, setNewTenantDetails] = React.useState({
		rent_deposit_amount: 0, 
		months_covered:1, 
		next_payment_date:moment().format('YYYY-MM-DD'),
		first_name: "",
		last_name: "",
		tenant_number: "",
		phone_number:"",
		unit_id: "",
	});

  const navigate = useNavigate();
  const { unassignedRentalUnits, isLoadingUnassignedRentalUnits, error, setNewData } = useUnassignedRentalUnits(id);

  const handleClickPopOver = (event, unit) => {
    setAnchorEl(event.currentTarget);
    setActiveUnit(unit); // Set the active unit for the popover
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleModalView = (modalView) => {
		if(modalView==='addTenant'){
			setNewTenantDetails((prev) => ({ ...prev, "unit_id": activeUnit.id,"rent_deposit_amount": activeUnit.unit_rent}));
		}
		setModal(modalView);
	};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function toSentenceCase(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  

  const handleClose = () => {
		setModal("");
		setErrors({});
		setOpen(false);
		setLoadedTenant(false);
		setIsExisitingTenant(true);
		setIsLoadedUnits(false);
		setNewTenantDetails({
			rent_deposit_amount: 0, 
			months_covered:1, 
			next_payment_date:moment().format('YYYY-MM-DD'),
			first_name: "",
			last_name: "",
			tenant_number: "",
			phone_number:"",
			unit_id: "",
		});
	};

	const handleChangeTenantDetails = (event) => {
		const { name, value } = event.target;
    	setNewTenantDetails((prev) => ({ ...prev, [name]: value }));
		if (!toString(value).trim()) {
      		setErrors((prev) => ({ ...prev, [name]: `${name} is required`}));
		} else {
			setErrors((prev) => ({ ...prev, [name]: '' }));
		}
	};

	const handleChangeTenantNumber = async (e) => {
		setLoadedTenant(false);
		setIsExisitingTenant(true);
		setIsLoadedUnits(false);
		setLoadingTenantsError("");
		const phone_number = e.target.value;
		if (!phone_number.trim()) {
      		setErrors((prev) => ({ ...prev, 'phone_number': `The Tenant's phone number is required`}));
		} else {
			setErrors((prev) => ({ ...prev, 'phone_number': '' }));
		}
		if (phone_number.length === 12) {
			setNewTenantDetails((prev) => ({ ...prev, 'phone_number': phone_number }));
			try {
				const response = await axios.get(`${baseURL}/tenants?username=${phone_number}`);
				setTenantDetails(response.data.data);
        setNewTenantDetails((prev)=> ({...prev, tenant_number: response.data.data.tenant_number}))
				setLoadedTenant(true);
				setLoadingTenantDetails(false);
				setLoadingTenantsError("");
				return true;
			} catch (err) {
				setIsExisitingTenant(false);
				setLoadingTenantDetails(false);
				setErrorLoadingTenantDetails(true);
				setLoadingTenantsError("");
				return false;
			}
		} else {
			setLoadingTenantsError("Please type a complete phone number with 12 digits including 256");
			setNewTenantDetails((prev) => ({ ...prev, "first_name": "", "last_name": "" }));
		}
	};

	const [errors, setErrors] = React.useState({});
	const propertySchema = isExistingTenant ? yup.object().shape({
		unit_id: yup.string().required("Please select a unit attached to the property above"),
		tenant_number: yup.string().required("Input a the tenant's phone number to start the process"),
		rent_deposit_amount: yup.number().required("This field is required"),
		months_covered: yup.number().required("This field is required"),
		next_payment_date: yup.date().required("This field is required"),
	}) : yup.object().shape({
		unit_id: yup.string().required("Please select a unit attached to the property above"),
		// tenant_number: yup.string().required("Input a the tenant's phone number to start the process"),
		first_name: yup.string().required("Input the tenant's First Name"),
		last_name: yup.string().required("Input the tenant's Last Name"),
		rent_deposit_amount: yup.number().required("This field is required"),
		months_covered: yup.number().required("This field is required"),
		next_payment_date: yup.date().required("This field is required"),

	}) ;

	const handleSubmitTenant = async () => {
		try {
			await propertySchema.validate(newTenantDetails, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			console.log(newError)
			return
		}
		
		try {
			if (isExistingTenant) {
				await axios.post(`${baseURL}/landlords/rentals/units/assign`, {
					unit_id: newTenantDetails.unit_id,
					tenant_number: newTenantDetails.tenant_number,
					rent_deposit_amount: newTenantDetails.rent_deposit_amount, 
					months_covered:newTenantDetails.months_covered, 
					next_payment_date:newTenantDetails.next_payment_date,
				});
			} else {
				const response = await axios.post(`${baseURL}/accounts/users`, {
					first_name: newTenantDetails.first_name,
					last_name: newTenantDetails.last_name,
					email: "dummy@email.com",
					phone_number: newTenantDetails.phone_number.slice(3),
					password: "@Rent123",
					username: `+${newTenantDetails.phone_number}`,
					to_activate: "True",
					unit_id: newTenantDetails.unit_id,
					rent_deposit_amount: newTenantDetails.rent_deposit_amount, 
					months_covered:newTenantDetails.months_covered, 
					next_payment_date:newTenantDetails.next_payment_date,
				});
			}
			setOpen(false);
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("success");
			setMessage("Tenant Added Successfully");
			// dispatch(setRefreshCount());
			setLoadedTenant(false);
			setIsExisitingTenant(true);
			setIsLoadedUnits(false);
      setNewData(true);
			setNewTenantDetails({
				rent_deposit_amount: 0, 
				months_covered:1, 
				next_payment_date:moment().format('YYYY-MM-DD'),
				first_name: "",
				last_name: "",
				tenant_number: "",
			  phone_number:"",
				unit_id: "",
			});
		} catch (err) {
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("error");
			setMessage("Tenant Addition failed, Please check data provided");
		}
	};

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1, padding: "2rem" }}>
          <Typography variant="h4" gutterBottom>
            Unassigned Rental Units
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Unit Name</StyledTableCell>
                  <StyledTableCell align="left">Unit Type</StyledTableCell>
                  <StyledTableCell align="left">Unit Size(m2)</StyledTableCell>
                  <StyledTableCell align="left">Rent Currency</StyledTableCell>
                  <StyledTableCell align="left">Unit Rent</StyledTableCell>
                  <StyledTableCell align="left">
                    Unit Rent Cycle
                  </StyledTableCell>
                  <StyledTableCell align="left">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingUnassignedRentalUnits ? (
                  <StyledTableRow>
                    {/* Loading Spinner or Empty Row */}
                  </StyledTableRow>
                ) : (
                  <>
                    {unassignedRentalUnits.length > 0 ? (
                      unassignedRentalUnits
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell component="th" scope="row">
                              {row.related_rental_unit.unit_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {toSentenceCase(
                                row.related_rental_unit.unit_type
                              )}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {" "}
                              {row.related_rental_unit.unit_size}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.rent_currency}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.unit_rent}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {toSentenceCase(row.unit_rent_cycle)}
                            </StyledTableCell>
                            <StyledTableCell>
                              <IconButton
                                color="primary"
                                size="small"
                                aria-describedby={popperId}
                                onClick={(event) =>
                                  handleClickPopOver(event, row)
                                }
                              >
                                <MoreHorizIcon />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={7}>
                          No data Available
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>{/* Pagination can go here */}</TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {/* UnitsPopover for handling actions */}
          <UnassgnedUnitsPopover
            popperId={popperId}
            openPopper={openPopper}
            anchorEl={anchorEl}
            handleModalView={handleModalView}
            handleClosePopOver={handleClosePopOver}
            handleClickOpen={handleClickOpen}
            activeUnit={activeUnit}
            navigate={navigate}
          />
        </Box>
      </Box>
      {modal === "addTenant" ? (
        <FormDialog
          open={open}
          handleClose={handleClose}
          enableActions={true}
          content={
            <AddTenantsFromUnoccupiedForm
              handleChangeTenantNumber={handleChangeTenantNumber}
              tenant={tenantDetails}
              errorTenant={errorLoadingTenantDetails}
              loadedTenant={loadedTenant}
              errors={errors}
              unit={activeUnit}
              // handleChangeRental={handleSelectChange}
              isExistingTenant={isExistingTenant}
              handleChangeTenantDetails={handleChangeTenantDetails}
              loadingTenantsError={loadingTenantsError}
            />
          }
          title={`Attach Tenant to ${activeUnit.related_rental_unit.unit_name}`}
          handleSubmit={handleSubmitTenant}
          buttonText="submit"
          buttonClass={"add-button"}
        />
      ) : modal === "delete" ? (
        <FormDialog
          title="Delete Unit"
          enableActions={true}
          open={open}
          content="Are you sure you want to delete this Unit?"
          handleClose={handleClose}
          buttonText="Delete Unit"
        />
      ) : null}
    </Box>
  );
}
