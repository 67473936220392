import * as React from "react";
import { FormControl, Grid, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function RentalsEditForm({rentalName, errors, handleChangeRentalName, propertyUse, handleChangePropertyUse, propertyLocation, handleChangeLocation, locationCounty, handleChangeLocationCounty, security, handleChangeSecurity, hospitalAccess, handleChangeHospitalAccess, roadAccess, handleChangeRoadAccess, parking, handleChangeParking, fibre, handleChangeFibre, elevator, handleChangeElevator, gym, handleChangeGym, pool, handleChangePool  }) {
	return (
		<>
			<Grid container component="form" noValidate autoComplete="off" spacing={2} sx={{marginTop:"1px"}}>
				<Grid item xs={12} sm={6}>
					<TextField size="small" fullWidth value={rentalName} label="Property Name" onChange={handleChangeRentalName} error={Boolean(errors.rental_name)} helperText={errors.rental_name} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Property Use Type</InputLabel>
						<Select label="Property Use Type" labelId="rental-type" id="rental-type-id" defaultValue={propertyUse} onChange={handleChangePropertyUse}>
                            <MenuItem value="MIXED USE">MIXED USE</MenuItem>
							<MenuItem value="RESIDENTIAL">RESIDENTIAL</MenuItem>
							<MenuItem value="COMMERCIAL">COMMERCIAL</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						label="Property Location (City)"
						labelId="rental-type"
						id="rental-type-id"
						fullWidth
						size="small"
						select
						value={propertyLocation}
						onChange={handleChangeLocation}
						error={Boolean(errors.location)} helperText={errors.location}
					>
						<MenuItem value="KAMPALA">Kampala</MenuItem>
					</TextField>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						// select
						label="Property Location (Parish and County)"
						labelId="rental-location-county"
						value={locationCounty}
						size="small"
						onChange={handleChangeLocationCounty}
						error={Boolean(errors.rental_name)} 
						helperText={errors.rental_name}
						sx={{ width:"100%" }}
					/>
				</Grid>
				<Grid item xs={12} sm={5} spacing={2}>
					<FormGroup>
						<FormControlLabel control={<Checkbox checked={security} onChange={handleChangeSecurity} />} label="Has 24 Hour Security?" />
                        <FormControlLabel control={<Checkbox checked={hospitalAccess} onChange={handleChangeHospitalAccess}/>} label="Hospital Access in less than 10 minutes" />
						<FormControlLabel control={<Checkbox checked={roadAccess} onChange={handleChangeRoadAccess}/>} label="Main Road Access in less than 5 minutes" />
                        <FormControlLabel control={<Checkbox checked={parking} onChange={handleChangeParking}/>} label="Has Ample Parking Space" />
					</FormGroup>
				</Grid>
				<Grid item xs={12} sm={7} spacing={2}>
					<FormGroup>
                        <FormControlLabel control={<Checkbox checked={fibre} onChange={handleChangeFibre}/>} label="Has Fibre/Wifi?" />
						<FormControlLabel control={<Checkbox checked={elevator} onChange={handleChangeElevator}/>} label="Has Elevator?" />
                        <FormControlLabel control={<Checkbox checked={gym} onChange={handleChangeGym}/>} label="Has Gym?" />
						<FormControlLabel control={<Checkbox checked={pool} onChange={handleChangePool}/>} label="Has Swimming Pool?" />
					</FormGroup>
				</Grid>
			</Grid>
		</>
	);
}