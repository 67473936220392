import * as React from "react";
import { Stack, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaRegEye } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import moment from "moment";




export default function TenantsTable(props) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [showDetails, setShowDetails] = React.useState(false);
	const [activeID, setActiveID] = React.useState(null);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const toggleDetailsView = (rowId) => {
		setActiveID(rowId);
		setShowDetails(!showDetails);
	};

	const handleAction = (event, row) => {
		props.handleClickPopOver(event, row);
	};

	const handleClickName = (row)=>{
		props.handleNavigateTenantPayments(row);
	}


	return (
		<>
			<TableContainer sx={{ display: { xs: "none", md: "block" } }} component={Paper}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Tenant Name</StyledTableCell>
							<StyledTableCell align="left">Duration</StyledTableCell>
							<StyledTableCell align="left">Unit Name</StyledTableCell>
							<StyledTableCell align="left">Contact Info</StyledTableCell>
							<StyledTableCell align="left">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.isLoadingRentalTenants ? (
							<StyledTableRow></StyledTableRow>
						) : (
							<>
								{(rowsPerPage > 0 ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.rows).map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell component="th" scope="row">
											<Link underline="hover" color="blue" onClick={()=>handleClickName(row)}>{row.related_tenant.first_name} {row.related_tenant.last_name}</Link>
										</StyledTableCell>
										<StyledTableCell align="left">Since {moment(row.date_added).format('LL')}</StyledTableCell>
										<StyledTableCell align="left">{row.related_rental_unit?.unit_name}</StyledTableCell>
										<StyledTableCell align="left">{"0" + row.related_tenant.phone_number}</StyledTableCell>
										<StyledTableCell align="left">
											<IconButton color="primary" size="small" aria-describedby={props.id} onClick={(e) => handleAction(e, row)}>
												<MoreHorizIcon />
											</IconButton>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</>
						)}
					</TableBody>
					<TableFooter>
						<TableRow></TableRow>
					</TableFooter>
				</Table>
				<TablePagination
					component="div"
					colSpan={12}
					count={props.rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows per page" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "1rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "1rem",
							marginTop: "10px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "1rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>

			{/* MOBILE VIEW */}
			<TableContainer sx={{ display: { xs: "block", md: "none" } }} component={Paper}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Tenant Name</StyledTableCell>
							<StyledTableCell align="center">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.isLoadingRentalTenants ? (
							<StyledTableRow></StyledTableRow>
						) : (
							<>
								{(rowsPerPage > 0 ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.rows).map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell align="left">
											<span>
												<Link underline="hover" color="blue" onClick={()=>{handleClickName(row);}}>{row.related_tenant.first_name} {row.related_tenant.last_name}</Link>
											</span>
											<Stack spacing={1} style={{ fontSize: "16px", marginTop: "7px" }}>
												<span style={{ cursor: "pointer", display: "flex", alignItems: "center", fontSize: "15px" }} onClick={() => toggleDetailsView(row.id)}>
													See{" "}
													{activeID === row.id && showDetails ? (
														<>
															{" "}
															less <IoMdEyeOff style={{ marginLeft: "5px", marginTop: "1px" }} />{" "}
														</>
													) : (
														<>
															{" "}
															more <FaRegEye style={{ marginLeft: "5px", marginTop: "1px" }} />{" "}
														</>
													)}
												</span>
												{activeID === row.id && showDetails ? (
													<>
														<span>{"0" + row.related_tenant.phone_number}</span>
														<span>
															<strong>Property:</strong> {row.related_rental_unit?.related_rental.rental_name}
														</span>
														<span>
															<strong>Duration:</strong> Since {moment(row.date_added).format('LL')}
														</span>
														<span>
															<strong>Unit Name:</strong> {row.related_rental_unit?.unit_name}
														</span>
													</>
												) : null}
											</Stack>
										</StyledTableCell>
										<StyledTableCell align="center" sx={{ width: "1%" }}>
											<IconButton color="primary" size="small" aria-describedby={props.id} onClick={(e) => handleAction(e, row)}>
												<MoreHorizIcon />
											</IconButton>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</>
						)}
					</TableBody>
					<TableFooter>
						<TableRow></TableRow>
					</TableFooter>
				</Table>
				<TablePagination
					component="div"
					colSpan={12}
					count={props.rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows per page" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "1rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "1rem",
							marginTop: "10px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "1rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>
		</>
	);
}
