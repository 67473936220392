import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

export default function UnassignedPropertyPopover({
  popperId,
  openPopper,
  anchorEl,
  handleClosePopOver,
  handleClickOpen,
  handleNavigateUnits,
  activeRental,
  handleModalView,
}) {
  const handleAction = (modalview) => {
    handleModalView(modalview);
    handleClickOpen();
    handleClosePopOver();
  };

  return (
    <div style={{ zIndex: 20000 }}>
      <Popover
        id={popperId}
        open={openPopper}
        anchorEl={anchorEl}
        onClose={handleClosePopOver}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Stack sx={{ padding: "10px", alignContent: "left" }}>
          <Button
            sx={{ color: "black", padding: "10px" }}
            onClick={() => handleAction("assignProperty")}
          >
            ASSIGN PROPERTY
          </Button>
          <Divider />
          <Button
            sx={{ color: "black", padding: "10px" }}
            onClick={() => handleNavigateUnits(activeRental)}
          >
            View Property Details
          </Button>
          <Divider />
          {/* Uncomment if you want to add functionality for editing property */}
          {/* <Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("edit")}>
            Edit Property
          </Button> */}
          <Divider />
          <Button
            sx={{ color: "red", padding: "10px" }}
            onClick={() => handleAction("delete")}
          >
            Delete Property
          </Button>
        </Stack>
      </Popover>
    </div>
  );
}
