import * as React from "react";
import { Stack, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaRegEye } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";
import { useTheme } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FEF0DE", // Match the PaymentsTable header background color
    color: theme.palette.common.black,
    fontSize: 16, // Match the PaymentsTable font size
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16, // Match the PaymentsTable font size
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover, // Match the PaymentsTable row background color
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function UnasignedPropertiesTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showDetails, setShowDetails] = React.useState(false);
  const [activeID, setActiveID] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function toSentenceCase(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const toggleDetailsView = (rowId) => {
    setActiveID(rowId);
    setShowDetails(!showDetails);
  };

  const handleAction = (event, rowid, details) => {
    props.setDetails(details);
    props.handleClickPopOver(event, rowid);
  };

  return (
    <>
      <TableContainer
        sx={{ display: { xs: "none", md: "block" } }}
        component={Paper}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Property Name</StyledTableCell>
              <StyledTableCell>Property Type</StyledTableCell>
              <StyledTableCell>Landlord</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoadingRentals ? (
              <StyledTableRow></StyledTableRow>
            ) : (
              <>
                {(rowsPerPage > 0
                  ? props.rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : props.rows
                ).map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">{row.rental_name}</StyledTableCell>
                    <StyledTableCell align="left">{toSentenceCase(row.rental_type)}</StyledTableCell>
                    <StyledTableCell align="left">{row.related_landlord.related_user.first_name} {row.related_landlord.related_user.last_name}</StyledTableCell>
                    <StyledTableCell align="left">{row.location}</StyledTableCell>
                    <StyledTableCell align="left">
                      <IconButton
                        color="primary"
                        size="small"
                        aria-describedby={props.id}
                        onClick={(e) => handleAction(e, row.id, row)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={5}
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* MOBILE */}
      <TableContainer
        sx={{ display: { xs: "block", md: "none" } }}
        component={Paper}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Property Name</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoadingRentals ? (
              <StyledTableRow></StyledTableRow>
            ) : (
              <>
                {(rowsPerPage > 0
                  ? props.rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : props.rows
                ).map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="left">
                      <span>{row.rental_name}</span>
                      <Stack
                        spacing={1}
                        style={{ fontSize: "16px", marginTop: "7px" }}
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                          onClick={() => toggleDetailsView(row.id)}
                        >
                          See{" "}
                          {activeID === row.id && showDetails ? (
                            <>
                              {" "}
                              less{" "}
                              <IoMdEyeOff
                                style={{ marginLeft: "5px", marginTop: "1px" }}
                              />{" "}
                            </>
                          ) : (
                            <>
                              {" "}
                              more{" "}
                              <FaRegEye
                                style={{ marginLeft: "5px", marginTop: "1px" }}
                              />{" "}
                            </>
                          )}
                        </span>
                        {activeID === row.id && showDetails && (
                          <>
                            <span>Type: {toSentenceCase(row.rental_type)}</span>
                            <span>Location: {row.location}</span>
                            <span>Number of Units: {row.units_count}</span>
                          </>
                        )}
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton
                        color="primary"
                        size="small"
                        aria-describedby={props.id}
                        onClick={(e) => handleAction(e, row.id, row)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={2}
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
