import * as React from "react";
import axios from "axios";
import {Box, Button, Typography, FormControl, InputLabel, Select, MenuItem,TextField} from "@mui/material";
import LandlordsTable from "components/Tables/LandlordsTable";
import TopSnack from "components/TopSnack";
import { useLandlords } from "services/hooks/rentals";
import { baseURL } from "services/API";
import FormDialog from "components/modal"; 
import AddLandlordForm from "components/Forms/LandlordForm"; 
import { useState } from "react";
import LandlordsPopover from "components/PopOvers/LandlordsPopOver";
import EditLandlordForm from "components/LandlordModals/EditLandlord";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLandloard } from "state";
import * as yup from "yup";

const Landlords = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [filterType, setFilterType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = React.useState("");
  const [selectedLandlord, setSelectedLandlord] = React.useState({});
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage,setErrorMessage] = useState("")
  const openPopper = Boolean(anchorEl);
  const popperId = openPopper ? "simple-popover" : undefined;
  const [activeRental, setActiveRental] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [message, setMessage] = React.useState("");

  const [newLandlord, setNewLandlord] = React.useState({
    phone_number: "",
    first_name: "",
    username: "",
    last_name: "",
    password: "",
    email: "",
    business_name: "",
    pay_percentage:undefined,
    pay_day:undefined
  });

  const [validationErrors, setValidationErrors] = React.useState({});
  const [isSubmittingData, setIsSubmittingData] = React.useState(false);

  const { landlords, isLoadingLandlords, error, setNewItemData } = useLandlords();

  const handleMenuClick = (event, landlord) => {
    setAnchorEl(event.currentTarget);
    setSelectedLandlord(landlord);
  };

   const handleClose = () => {
    setOpen(false);
    setModal("");
    setValidationErrors({});
  };

  const handleClickPopOver = (event, row) => {
    setAnchorEl(event.currentTarget);
    setActiveRental(row);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  }; 
  
  const handleClickOpen = () => {
    setOpen(true);
    handleClosePopOver();
  };

  const handleModalView = (modalView) => {
    setModal(modalView);
  };

  const handleViewLandlord = () => {
    dispatch(setLandloard(selectedLandlord));
    navigate(`/landlordDetails`, {state : selectedLandlord});
  };

  const handleSendMessage = async () => {
    if (selectedLandlord && message) {
      // await onSendMessage(selectedLandlord, message);
      handleDialogClose();
      setMessage("");
    }
  };

  const handleDelete = async () => {
    setIsSubmittingData(true);
    try {
        const response = await axios.delete(`${baseURL}/landlords/admin/landlords/details/${selectedLandlord.id}`);
        
        // Check if the response indicates a successful deletion
        if (response.status === 200) {
            setOpenSnack({
                open: true,
                message: "Landlord deleted successfully",
                severity: "success",
            });
        } else {
            setOpenSnack({
                open: true,
                message: "Failed to delete landlord",
                severity: "error",
            });
        }
    } catch (error) {
        // Handle errors from the delete request
        console.error(error);
        setOpenSnack({
            open: true,
            message: error.response?.data?.message || "Failed to delete landlord",
            severity: "error",
        });
    } finally {
        setIsSubmittingData(false);
    }
    handleClose(false);
    setNewItemData(true);
    setSelectedLandlord({});
};

  const handleDialogClose = () =>{
     setOpenDialog(false);
     setNewLandlord({
       phone_number: "",
       first_name: "",
       username: "",
       last_name: "",
       password: "",
       email: "",
       business_name: "",
       pay_percentage: undefined,
       pay_day: undefined
     });
     setValidationErrors({});
    };

  const userNameSchema = yup.object().shape({
    username: yup
      .string()
      .required("Username is required")
      .test(
        "check-username",
        "Username is already taken. Please Input another", // Error message if not unique
        async (value) => {
          if (!value) return true; // Skip validation if value is empty
          try {
            let formData = new FormData();
            formData.append("phone_number", value);
            const usernameCheck = await axios.post(
              `${baseURL}/accounts/users/details`,
              formData
            );
            return false; // Validation fails if username is taken
          } catch (error) {
            return true; // Validation passes if username is available
          }
        }
      ),
  });

  const normalFieldsSchema = yup.object().shape({
    phone_number: yup.string().required("Phone Numberis required").matches(/^(0\d{9})$/, "Phone number must be in the format 076337019"),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    password: yup.string().required("Password is required").min(6,"Password must be at least 6 characters"),
    email: yup.string().email("Email is invalid").required("Email is required"),
    business_name: yup.string().required("Business Name is required"),
    pay_percentage: yup.number().min(1,"Payment Percentage must be between 0 and 100%").max(100, "Payment Percentage must be between 0 and 100%").required("Pay Percentage is required"),
    pay_day: yup.string().required("Pay Day is required"),
  });

  const landlordSchema = normalFieldsSchema.concat(userNameSchema)

  const editLandlordSchema = yup.object().shape({
    phone_number: yup.string().required("Phone Numberis required").matches(/^(0\d{9})$/, "Phone number must be in the format 076337019"),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email("Email is invalid").required("Email is required"),
    business_name: yup.string().required("Business Name is required"),
    pay_percentage: yup.number().min(1,"Payment Percentage must be between 0 and 100%").max(100, "Payment Percentage must be between 0 and 100%").required("Pay Percentage is required"),
    pay_day: yup.string().required("Pay Day is required"),
  });


  const handleEditLandlord = async () => {
      try {
        await editLandlordSchema.validate(selectedLandlord, { abortEarly: false });
      } catch (error) {
        const newError = {};
        error.inner.forEach((err) => {
          newError[err.path] = err.message;
        });
        setValidationErrors(newError);
        return;
      }

    setIsSubmittingData(true);
    await axios
      .post(`${baseURL}/landlords/admin/landlords/details/${selectedLandlord.id}`,
        {
          user_details: {
            phone_number: selectedLandlord.phone_number,
            first_name: selectedLandlord.first_name,
            last_name: selectedLandlord.last_name,
            username:selectedLandlord.username,
            email: selectedLandlord.email,
          },
          business_name: selectedLandlord.business_name,
          pay_day: selectedLandlord.pay_day,
          pay_percentage: selectedLandlord.pay_percentage,
        }
      )
      .then((response) => {setIsSubmittingData(false);})
      .catch((error) => {setIsSubmittingData(false);});
    handleClose();
    setNewItemData(true);
    setSelectedLandlord({});
  };

  const handleAddLandlordSubmit = async () => {
    try {
			await landlordSchema.validate(newLandlord, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setValidationErrors(newError)
			return
		}

    setIsSubmittingData(true);
    try {
      setSuccessMessage("")
      setErrorMessage("")
      await axios.post(`${baseURL}/accounts/users/landlords`, newLandlord);
      
      setSuccessMessage("Landlord added successfully");
      setNewItemData(true);
      setNewLandlord({
          phone_number: "",
          first_name: "",
          username: "",
          last_name: "",
          password: "",
          email: "",
          business_name: "",
          pay_percentage:undefined,
          pay_day:undefined
      });
      setValidationErrors({});
      handleDialogClose();
    } catch (error) {
        console.log(error.response?.data?.error); 
        setErrorMessage(error.response?.data?.error)
        
    } finally {

        setIsSubmittingData(false);
    }
};

  

  const handleFilterTypeChange = (event) => setFilterType(event.target.value);

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setSelectedLandlord((prev) => ({ ...prev, [name]: value }));
    if (!value.trim()) {
      setValidationErrors((prev) => ({ ...prev, [name]: `${name} is required`}));
    } else {
      setValidationErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setNewLandlord((prev) => ({ ...prev, [name]: value }));
      if (name === 'username') {
        try {
        await userNameSchema.validate({username: value}, {abortEarly:false});
        setValidationErrors((prev) => ({ ...prev, 'username': "" }));
      } catch (error) {
        const newError = {}
        error.inner.forEach(err => {
          setValidationErrors((prev) => ({ ...prev, 'username': err.message}));
        })
      }
    } else {
      if (!value.trim()) {
        setValidationErrors((prev) => ({ ...prev, [name]: `${name} is required`}));
      } else {
        setValidationErrors((prev) => ({ ...prev, [name]: '' }));
      }
    }
  };

  if (successMessage) {
    setTimeout(() => {
      setSuccessMessage(""); 
    }, 2000);
  }
  return (
    <Box m="10px">
      <Box container spacing={2}>
        <Box item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
          >
            Landlords
          </Typography>

          {successMessage && (
            <p sx={{ color: "green", fontSize: "14px" }}>{successMessage}</p>
          )}

          <Box sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}>
            <FormControl variant="outlined" size="small" sx={{ flexGrow: 1 }}>
              <InputLabel>Filter By</InputLabel>
              <Select
                value={filterType}
                onChange={handleFilterTypeChange}
                label="Filter By"
                sx={{ width: 300, fontSize: 14 }}
              >
                <MenuItem value="landlordNumber">Landlord Number</MenuItem>
                <MenuItem value="landlordName">Landlord Name</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              Add Landlord
            </Button>
          </Box>
          <LandlordsTable
            data={landlords}
            handleMenuClick={handleMenuClick}
            handleClickPopOver={handleClickPopOver}
            loading={isLoadingLandlords}
            error={error}
            setSelectedLandlord={setSelectedLandlord}
          />
        </Box>
      </Box>

      <TopSnack openSnack={openSnack} setOpenSnack={setOpenSnack} />

      <LandlordsPopover
        popperId={popperId}
        openPopper={openPopper}
        anchorEl={anchorEl}
        handleClosePopOver={handleClosePopOver}
        handleClickOpen={handleClickOpen}
        navigate={navigate}
        selectedLandlord={selectedLandlord}
        setSelectedLandlord={setSelectedLandlord}
        handleViewLandlord={handleViewLandlord}
        handleModalView={handleModalView}
      />

      <FormDialog
        open={openDialog}
        title="Add Landlord"
        text="Please fill in the details of the new landlord."
        content={
          <AddLandlordForm
            newLandlord={newLandlord}
            handleChange={handleChange}
            validationErrors={validationErrors}
          />
        }
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        handleClose={handleDialogClose}
        handleSubmit={handleAddLandlordSubmit}
        isSubmittingData={isSubmittingData}
      />

      {modal === "delete" ? (
        <FormDialog
          title="Delete Property"
          enableActions={true}
          open={open}
          content="Are you sure you want to delete this Property?"
          handleClose={handleClose}
          handleSubmit={handleDelete}
          buttonText="Delete"
        />
      ) : modal === "edit" ? (
        <FormDialog
          open={open}
          title="Edit Landlord"
          text="Edit landlord Details"
          content={
            <EditLandlordForm
              newLandlord={selectedLandlord}
              handleChange={handleEditChange}
              validationErrors={validationErrors}
            />
          }
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleClose={handleClose}
          handleSubmit={handleEditLandlord}
          isSubmittingData={isSubmittingData}
        />
      ) : modal === "message" ? (
        <FormDialog
          open={open}
          title="Send Message"
          content={
            <TextField
              autoFocus
              margin="dense"
              label="Message"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          }
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleClose={handleClose}
          handleSubmit={handleSendMessage}
          isSubmittingData={isSubmittingData}
        />
      ) : null}
    </Box>
  );
};

export default Landlords;
