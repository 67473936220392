import React, {useState, useEffect} from "react";
import { Box, Stack, Typography, Card, CardContent, Button, Divider, TextField, FormControl, Link, InputLabel, Select,Breadcrumbs, MenuItem, FormControlLabel, Checkbox, Grid } from "@mui/material";
import { FlexStart, FlexCenter, FlexRowCenter } from "components/FlexBox/FlexBox";
import ImageUploader from "components/Images/ImageUploader";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import CountertopsOutlinedIcon from "@mui/icons-material/CountertopsOutlined";
import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import axios from "axios";
import { baseURL } from "services/API";
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ApartmentIcon from "@mui/icons-material/Apartment";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { useLocation } from "react-router-dom";
import FlexBetween from "components/FlexBetween";


const facilityIcons = {
	marginRight: "8px",
	fontSize: "20px",
	color: "#52524e",
};

const formatNumberString = (numberString) => {
	const number = Number(numberString);
	if (isNaN(number)) {
		return "Invalid number string";
	}
	return number.toLocaleString();
};

const unitSchema = yup.object().shape({
  unit_name: yup.string().required("Please input the name of the unit "),
  unit_rent: yup.number().required("This field is required"),
  unit_rent_cycle: yup.string().required("This field is required"),
  unit_type: yup.string().required("This field is required"),
  rent_currency: yup.string().required("This field is required"),
  number_of_bedrooms: yup.number().required("This field is required"),
  number_of_bathrooms: yup.number().required("This field is required"),
});


const UnitCreate = () => {
	const token = useSelector((state) => state.token)
  	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [files, setFiles] = useState([]);
	const [open, setOpen] = React.useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const id  = useSelector((state) => state.property_id);


	const [unitName, setUnitName] = useState("");
	const [rentCurrency, setRentCurrency] = useState("");
	const [rentCycle, setRentCycle] = useState("");
	const [unitType, setUnitType] = useState("");
	const [unitRent, setUnitRent] = useState("");
	const [bedrooms, setBedrooms] = useState("");
	const [bathrooms, setBathrooms] = useState("");
	const [insideKitchen, setInsideKitchen] = useState(false);
	const [insideBathroom, setInsideBathroom] = useState(false);
	const [dstv, setDstv] = useState(false);
	const [fibre, setFibre] = useState(false);
	const [funished, setFurnished] = useState(false);
	const [store, setStore] = useState(false);
	const [hasDownPayment, setHasDownPayment] = useState("");
	const [downPayment, setDownPayment] = useState("");
	const [hasSecurityFee, setHasSecurityFee] = useState("");
	const [securityFee, setSecurityFee] = useState("");
	const [unitData, setUnitData] = useState({related_rental: id})
	const [submittingData, setSubmittingData] = useState(false);
	const [errors, setErrors] = React.useState({});


	const handleChangeUnitName = (event) => {
		setUnitName(event.target.value);
		setUnitData({...unitData, unit_name: event.target.value})
		if (!event.target.value.trim()) {
      		setErrors((prev) => ({ ...prev, 'unit_name': `The Unit Name is required`}));
		} else {
			setErrors((prev) => ({ ...prev, "unit_name": "" }));
		}
	};

	const handleChangeRentCurrency = (event) => {
		setRentCurrency(event.target.value);
		setUnitData({...unitData, rent_currency: event.target.value})
		if (!event.target.value.trim()) {
      		setErrors((prev) => ({ ...prev, 'rent_currency': `This field is required`}));
		} else {
			setErrors((prev) => ({ ...prev, "rent_currency": "" }));
		}
	};

	const handleChangeRentCycle = (event) => {
		setRentCycle(event.target.value);
		setUnitData({...unitData, unit_rent_cycle: event.target.value})
		if (!event.target.value.trim()) {
      		setErrors((prev) => ({ ...prev, 'unit_rent_cycle': `This field is required`}));
		} else {
			setErrors((prev) => ({ ...prev, "unit_rent_cycle": "" }));
		}
	};

	const handleChangeUnitType = (event) => {
		setUnitType(event.target.value);
		setUnitData({...unitData, unit_type: event.target.value})
		if (!event.target.value.trim()) {
      		setErrors((prev) => ({ ...prev, 'unit_type': `This field is required`}));
		} else {
			setErrors((prev) => ({ ...prev, "unit_type": "" }));
		}
	};

	const handleChangeUnitRent = (event) => {
		setUnitRent(event.target.value);
		setUnitData({...unitData, unit_rent: event.target.value})
		if (!event.target.value.trim()) {
      		setErrors((prev) => ({ ...prev, 'unit_rent': `This field is required`}));
		} else {
			setErrors((prev) => ({ ...prev, "unit_rent": "" }));
		}
	};

	const handleChangeBedrooms = (event) => {
		setBedrooms(event.target.value);
		setUnitData({...unitData, number_of_bedrooms: event.target.value})
		if (!event.target.value.trim()) {
      		setErrors((prev) => ({ ...prev, 'number_of_bedrooms': `This field is required`}));
		} else {
			setErrors((prev) => ({ ...prev, "number_of_bedrooms": "" }));
		}
	};

	const handleChangeBathrooms = (event) => {
		setBathrooms(event.target.value);
		setUnitData({...unitData, number_of_bathrooms: event.target.value})
		if (!event.target.value.trim()) {
      		setErrors((prev) => ({ ...prev, 'number_of_bathrooms': `This field is required`}));
		} else {
			setErrors((prev) => ({ ...prev, "number_of_bathrooms": "" }));
		}
	};

	const handleChangeInsideBathroom = (event) => {
		setInsideBathroom(event.target.checked);
		setUnitData({...unitData, has_inside_bathroom: event.target.value})
	};
	
	const handleChangeInsideKitchen = (event) => {
		setInsideKitchen(event.target.checked);
		setUnitData({...unitData, has_inside_kitchen: event.target.value})
	}

	const handleChangeDstv = (event) => {
		setDstv(event.target.checked);
		setUnitData({...unitData, has_dstv_connection: event.target.value})
	};
	
	const handleChangeFibre = (event) => {
		setFibre(event.target.checked);
		setUnitData({...unitData, has_fibre_connection: event.target.value})
	};

	const handleChangeStore = (event) => {
		setStore(event.target.checked);
		setUnitData({...unitData, has_store: event.target.value})
	};
	
	const handleChangeFurnished = (event) => {
		setFurnished(event.target.checked);
		setUnitData({...unitData, is_fully_furnished: event.target.value})
	};

	const handleChangeHasDownPayment= (event) => {
		setHasDownPayment(event.target.checked);
		setUnitData({...unitData, has_down_payment: event.target.value})
	};

	const handleChangeHasSecurityFee = (event) => {
		setHasSecurityFee(event.target.checked);
		setUnitData({...unitData, has_security_fee: event.target.value})
	};

	const handleChangeDownPayment= (event) => {
		setDownPayment(event.target.value);
		setUnitData({...unitData, unit_cycle_down_payment: event.target.value})
	};

	const handleChangeSecurityFee = (event) => {
		setSecurityFee(event.target.value);
		setUnitData({...unitData, unit_rent_security_fee: event.target.value})
	};


	const handleRentalUnitFormSubmit = async () => {

		try {
			await unitSchema.validate(unitData, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
        }

		try {
			setSubmittingData(true)
			await axios.post(`${baseURL}/landlords/rentals/units`, unitData);
			// setSuccess(true);
			// setLoading(false);
			setSubmittingData(false)
			setOpen(false);
			navigate("/propertyDetailsTab");
		} catch (err) {
			console.log(err);
			// setLoading(false);
			// setError(true);
			// setErrcode(err.message);
		}
	};


  return (
    <Box m="20px" p="20px">
		<Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => navigate("/propertyDetailsTab")}
          >
            <ApartmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Property Details
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <b>Create Unit</b>
          </Typography>
        </Breadcrumbs>
		    <Typography variant="h4" marginBottom={"10px"}>Unit Details</Typography>
        <Grid container spacing={2}>
			
          <Grid item xs={12} sm={6}>
            <Stack spacing={3} sx={{ marginBottom: "10px" }}>
              <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Unit Name"
                  onChange={handleChangeUnitName}
                  fullWidth={true}
                  margin="dense"
                  name="unit_name"
                  error={Boolean(errors.unit_name)}
                  helperText={errors.unit_name}
                  autoFocus
                />

                <FlexBetween sx={{ marginY:"10px" }}>
                  <TextField
                    select
                    label="Unit Type"
                    labelId="unit-type"
                    value={unitType}
                    sx={{ width:"49%" }}
                    name="unit_type"
                    error={Boolean(errors.unit_type)}
                    helperText={errors.unit_type}
                    onChange={handleChangeUnitType}
                  >
                    <MenuItem value="REGULAR">REGULAR</MenuItem>
                    <MenuItem value="CONDOMINIUM">CONDOMINIUM</MenuItem>
                    <MenuItem value="SEMI-DETACHED">SEMI DETACHED</MenuItem>
                  </TextField>

                  <TextField
                    select
                    label="Rent Cycle"
                    labelId="rent-cycle"
                    value={rentCycle}
                    sx={{ width:"49%" }}
                    name="unit_rent_cycle"
                    error={Boolean(errors.unit_rent_cycle)}
                    helperText={errors.unit_rent_cycle}
                    onChange={handleChangeRentCycle}
                  >
                    <MenuItem value="MONTHLY">MONTHLY</MenuItem>
                  </TextField>
                </FlexBetween>

                <FlexBetween sx={{ marginY:"10px" }}>
                  <TextField
                    select
                    label="Rent Currency"
                    labelId="rent-currency"
                    value={rentCurrency}
                    sx={{ width:"49%" }}
                    name="rent_currency"
                    error={Boolean(errors.rent_currency)}
                    helperText={errors.rent_currency}
                    onChange={handleChangeRentCurrency}
                  >
                    <MenuItem value="UGX">UGX</MenuItem>
                  </TextField>

                  <TextField
                    required
                    id="outlined-required"
                    label="Unit Rent in UGX per Month"
                    onChange={handleChangeUnitRent}
                    fullWidth={true}
                    margin="dense"
                    type="number"
                    name="unit_rent"
                    sx={{ width:"49%" }}
                    error={Boolean(errors.unit_rent)}
                    helperText={errors.unit_rent}
                    autoFocus
                  />	
                </FlexBetween>

                <FlexBetween sx={{ marginY:"10px" }}>
                  <TextField
                    select
                    label="Number of Bedrooms"
                    labelId="bedrooms"
                    value={bedrooms}
                    sx={{ width:"49%" }}
                    name="number_of_bedrooms"
                    error={Boolean(errors.number_of_bedrooms)}
                    helperText={errors.number_of_bedrooms}
                    onChange={handleChangeBedrooms}
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                  </TextField>

                  <TextField
                    select
                    label="Number of Bathrooms"
                    labelId="bathrooms"
                    value={bathrooms}
                    sx={{ width:"49%" }}
                    name="number_of_bathrooms"
                    error={Boolean(errors.number_of_bathrooms)}
                    helperText={errors.number_of_bathrooms}
                    onChange={handleChangeBathrooms}
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                  </TextField>
                </FlexBetween>
                
              </Box>
              
              <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
                <Typography variant="h5">Amenities (Select all that apply to the unit)</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <FlexCenter>
                        <ChairOutlinedIcon sx={facilityIcons} />
                        <FormControlLabel control={<Checkbox checked={insideKitchen} onChange={handleChangeInsideKitchen}/>} label="Has Inside Kitchen?" />
                      </FlexCenter>
                      <FlexCenter>
                        <BedOutlinedIcon sx={facilityIcons} />
                        <FormControlLabel control={<Checkbox checked={insideBathroom} onChange={handleChangeInsideBathroom}/>} label="Has Inside Bathroom?" />
                      </FlexCenter>
                      <FlexCenter>
                        <CountertopsOutlinedIcon sx={facilityIcons} />
                        <FormControlLabel control={<Checkbox checked={dstv} onChange={handleChangeDstv}/>} label="Has Dstv Connection?" />
                      </FlexCenter>
                      <FlexCenter>
                        <WifiOutlinedIcon sx={facilityIcons} />
                        <FormControlLabel label="Has Fibre Connection?" control={<Checkbox checked={fibre} onChange={handleChangeFibre}/>}  />
                      </FlexCenter>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <FlexCenter>
                        <ChairOutlinedIcon sx={facilityIcons} />
                        <FormControlLabel label="Is Fully Furnished?" control={<Checkbox checked={funished} onChange={handleChangeFurnished}/>}  />
                      </FlexCenter>
                      <FlexCenter>
                        <ShowerOutlinedIcon sx={facilityIcons} />
                        <FormControlLabel label="Has a Store?" control={<Checkbox checked={store} onChange={handleChangeStore}/>}  />
                      </FlexCenter>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Grid>
          <Grid item sx={{ display: { xs: "none", sm: "block" } }} xs={12} sm={6}>
            <Card sx={{marginBottom: "25px"}}>
              <CardContent sx={{ padding: 0 }}>
                <FlexStart sx={{ padding: "16px" }}>
                {/* <LandLordAvatar /> */}
                  <Box sx={{ marginLeft: "8px" }}>
                    <Typography variant="body1" sx={{ fontSize: "17px" }}>
                      Add Unit Images
                    </Typography>
                  </Box>
                </FlexStart>
                <Divider />
                <Stack spacing={3} sx={{ padding: "16px 16px 5px 16px" }}>
                  <ImageUploader files={files} setFiles={setFiles} maxFiles={3}/>
                </Stack>
              </CardContent>
            </Card>

            <Card >
              <CardContent sx={{ padding: 0 }}>
                <FlexStart sx={{ padding: "16px" }}>
                {/* <LandLordAvatar /> */}
                  <Box sx={{ marginLeft: "8px" }}>
                    <Typography variant="body1" sx={{ fontSize: "17px" }}>Terms and Conditions</Typography>
                  </Box>
                </FlexStart>
                <Divider />
                <Stack spacing={3} sx={{ padding: "16px" }}>
                  <FlexCenter>
                    <FormControlLabel label="Does the Unit have a down payment?" control={<Checkbox checked={hasDownPayment} onChange={handleChangeHasDownPayment}/>}  />
                    {hasDownPayment && (
                      <TextField size="small" fullWidth value={downPayment} label="How much in UGX is the down payment?" onChange={handleChangeDownPayment} />
                    )}
                  </FlexCenter>
                  <FlexCenter>
                    <FormControlLabel label="Does the Unit have a security deposit?" control={<Checkbox checked={hasSecurityFee} onChange={handleChangeHasSecurityFee}/>}  />
                    {hasSecurityFee && (
                      <TextField size="small" fullWidth value={securityFee} label="How much is the secuity deposit?" onChange={handleChangeSecurityFee} />
                    )}
                  </FlexCenter>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Button variant="contained" endIcon={<SendIcon />} onClick={handleRentalUnitFormSubmit} sx={{padding:"15px"}}>Submit Unit Details</Button>
          </Grid>
			</Grid>
    </Box>
  );
};

export default UnitCreate;