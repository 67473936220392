import React, { useState, useEffect } from "react";
import axios from "axios";
import FormDialog from "components/modal";
import { baseURL } from "services/API";
import {Box,Button,Typography,Grid,styled,} from "@mui/material";
import LandlordTenantPaymentsTable from "components/Tables/LandlordTenantPaymentsTable";
import LandlordTenantPaymentsForm from "components/Forms/LandlordTenantPaymentsForm"
import { useTenants, useRentalUnits, useMonthsAndYears, useTenantPayments } from "services/hooks/rentals";
import { setRefreshCount } from "state";
import { useSelector, useDispatch } from "react-redux";
import BasicCard from "components/Card";
import FlexBetween from "components/FlexBetween";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";
import moment from "moment";
import * as yup from 'yup';

// Style definition for the title container
const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const LandlordTenantsPayments = () => {
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [newPayment, setNewPayment] = useState({
    related_rental_unit: "",
    related_tenant: "",
    amount: undefined,
    related_month: "",
    payment_method: "",
    date_paid: moment().format('YYYY-MM-DD'),
  });

  const dispatch = useDispatch()

  const [amount, setAmount] = useState(newPayment.amount)
  const [month, setMonth] = useState(newPayment.related_month)
  const [paymentMethod, setPaymentMethod] = useState(newPayment.payment_method)
  const [datePaid, setDatePaid] = useState(newPayment.date_paid)
  const [initialPayment, setInitialPayment] = React.useState(false);
  const [hasSecurityDeposit, setHasSecurityDeposit] = React.useState(false);
  const [nextPaymentDate, setNextPaymentDate] = useState("")
  const [errors, setErrors] = useState({})

  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [monthOptions, setMonthOptions] = useState([]);

  const landlord = useSelector((state) => state.landloard);
  const tenantOccupancy = useSelector((state) => state.tenantOccupancy);
  const {tenantPayments, isLoadingTenantPayments, errorTenantPayments} = useTenantPayments(tenantOccupancy.id)
  const { monthsAndYears, isLoadingMonthsAndYears } = useMonthsAndYears();


	const handleChangeAmount = (event) => {
    const {value} = event.target
		setAmount(value);
    if (!value.trim()) {
      setErrors(prev=> ({...prev , 'amount': 'This is required'}))
    } else {
      setErrors((prev) => ({ ...prev, amount: "" }));
    }
	};

	const handleChangeMonth = (event) => {
    const {value} = event.target
		setMonth(value);
    if (!toString(value).trim()) {
      setErrors((prev) => ({ ...prev, related_month: "This is required" }));
    } else {
      setErrors((prev) => ({ ...prev, related_month: "" }));
    }
	};

  const handleChangeDatePaid = (event) => {
    const {value} = event.target
		setDatePaid(value);
	};

  const handleChangeNextPaymentDate = (event) => {
    const {value} = event.target
		setNextPaymentDate(value);
	};

	const handleChangePaymentMethod = (event) => {
    const {value} = event.target
		setPaymentMethod(value);
    if (!toString(value).trim()) {
      setErrors((prev) => ({ ...prev, payment_method: "This is required" }));
    } else {
      setErrors((prev) => ({ ...prev, payment_method: "" }));
    }
	};

	const handleInitialPayment = (event) => {
		setInitialPayment(event.target.checked);
	};

  const handleSecurityDeposit = (event) => {
		setHasSecurityDeposit(event.target.checked);
	};

  const rowsPerPage = 5;

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewPayment({
      related_rental_unit: "",
      related_tenant: "",
      amount: undefined,
      related_month: "",
      payment_method: "",
      date_paid: "",
    });
    setInitialPayment(false);
    setHasSecurityDeposit(false);

  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPayment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (monthsAndYears) {
      setMonthOptions(
        monthsAndYears.map((monthYear) => ({
          value: monthYear.id,
          label: `${monthYear.month} ${monthYear.year}`,
        }))
      );
    }
  }, [monthsAndYears]);

  const paymentSchema = yup.object().shape({
    related_rental_unit: yup.number().required('This is required'),
    related_tenant: yup.number().required('This is required'),
    amount: yup.number().required('This is required'),
    related_month: yup.string().required('This is required'),
    payment_method: yup.string().required('This is required'),
    date_paid: yup.date().required('This is required'),
  });

  const handleSubmitTenantPayment = async () => {
    try {
			await paymentSchema.validate(
        {
          related_rental_unit: tenantOccupancy.related_rental_unit.id,
          related_tenant: tenantOccupancy.related_tenant.id,
          amount: amount,
          related_month: month,
          date_paid: datePaid,
          payment_method: paymentMethod,
          is_initial_payment: initialPayment,
          has_security_deposit: hasSecurityDeposit,
          next_payment_date: nextPaymentDate,
        }, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
		}
		try {
      const response = await axios.post(`${baseURL}/tenants/payments/custom`, {
        related_rental_unit: tenantOccupancy.related_rental_unit.id,
        related_tenant: tenantOccupancy.related_tenant.id,
        amount: amount,
        related_month: month,
        date_paid: datePaid,
        payment_method: paymentMethod,
        is_initial_payment: initialPayment,
        has_security_deposit: hasSecurityDeposit,
        next_payment_date: nextPaymentDate,
      });
      dispatch(setRefreshCount())
			setOpenDialog(false);
			// setOpenSnack({ ...openSnack, open: true});
			// setSeverity("success");
			// setMessage("Tenant Added Successfully");
		} catch (err) {
			// setOpenSnack({ ...openSnack, open: true});
			// setSeverity("error");
			// setMessage("Tenant Addition failed, Please check data provided");
		}
	};

  return (
    <Box m="20px">
      <TitleContainer>
        <Typography
          variant="h5"
          style={{ marginBottom: 0, fontWeight: "bold", fontSize: "25px", paddingLeft: 5 }}
        >
          Rent Payment History for {tenantOccupancy.related_tenant.first_name} {tenantOccupancy.related_tenant.last_name}
        </Typography>
      </TitleContainer>
      <Box sx={{ p: 3 }}>
        <FlexBetween>
          <BasicCard name="Days Left" value={15} />
          <BasicCard name="Unit Rent" value={750000} />
          <BasicCard name="Current Outstanding Balance" value={currencyFormatter(parseFloat(1500000))} />
          <BasicCard name="Total Complete Months" value={13} />

          <Button
            variant="contained"
            color="primary"
            sx={{ height: 56, width: 200 }}
            onClick={handleOpenDialog}
          >
            Record Payment
          </Button>
        </FlexBetween>

        <Box sx={{ mt:5 }}>
          <LandlordTenantPaymentsTable
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            paymentData={tenantPayments} // Using dummy data
          />
        </Box>
      </Box>

      <FormDialog
        open={openDialog}
        title="Add Payment"
        text="Fill out the details below to add a new payment."
        content={
          <LandlordTenantPaymentsForm 
            monthOptions = {monthsAndYears}
            isLoadingMonthsAndYears ={isLoadingMonthsAndYears} 
            handleChangeAmount={handleChangeAmount}
            handleChangeMonth={handleChangeMonth}
            month={month}
            handleChangeDatePaid={handleChangeDatePaid}
            handleChangeNextPaymentDate={handleChangeNextPaymentDate}
            handleChangePaymentMethod={handleChangePaymentMethod}
            paymentMethod={paymentMethod}
            datePaid={datePaid}
            handleInitialPayment={handleInitialPayment}
            initialPayment={initialPayment}
            handleSecurityDeposit={handleSecurityDeposit}
            hasSecurityDeposit={hasSecurityDeposit}
            errors={errors}
          />
        }
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmitTenantPayment}
        isSubmittingData={isSubmittingData}
      />
    </Box>
  );
};

export default LandlordTenantsPayments;
