import React from 'react';
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

export default function PopoverButton({name, icon, onClick}){

    return (
        <>
            <Button
                sx={{ color: "black", padding: "10px" }}
                onClick={onClick}
            >
                {icon}
                {name}
            </Button>
            <Divider />
        </>
    )
}
