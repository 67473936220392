import React, { useState } from 'react';
import { Button, Grid, Typography, Box } from '@mui/material';
import AddTenantForm from 'components/Forms/TenantsAddForm';
import TenantTable from 'components/Tables/LandlorTenantsTable';
import { styled } from '@mui/material/styles';
import { useTenants } from 'services/hooks/rentals';
import { useSelector } from 'react-redux';

const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const LandlordTenants = () => {
  const landlord = useSelector((state)=> state.landloard)
  
  const { tenants, loading, error } = useTenants(landlord.id); // Use the hook here
  const [openDialog, setOpenDialog] = useState(false);
  // const [tenants, setTenants] = useState(fetchedTenants || null); // Local state for tenants
  const [tenant, setTenant] = useState({
    name: '',
    contact: '',
    amountSaved: '',
    date: '',
    landlordName: '',
    landlordContact: '',
    email: '',
    address: '',
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading tenants</Typography>;

  return (
    <Box m="20px">
      {/* <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <TitleContainer>
            <Button variant="contained" color="primary" disabled={true} onClick={handleOpenDialog}>
              Add new Landlord Tenant
            </Button>
          </TitleContainer>
        </Grid>
      </Grid> */}
      {tenants && <TenantTable
        tenants={tenants} 
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />}
      <AddTenantForm
        open={openDialog}
        onClose={handleCloseDialog}
        // onSubmit={handleAddTenant}
        tenant={tenant}
        setTenant={setTenant}
      />
    </Box>
  );
};

export default LandlordTenants;
