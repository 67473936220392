import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/Tables/Styles/TableAndCellStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useTheme } from "@mui/material";

export default function ManagerDetailsTable({handleClickPopOver, handleNavigateUnits, attachedProperties, isLoadingAttachedProperties, errorAttachedProperties}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(
        event,
        Math.max(0, Math.ceil(count / rowsPerPage) - 1)
      );
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

 

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Rental Name</StyledTableCell>
            <StyledTableCell>Business Name</StyledTableCell>
            <StyledTableCell> Landlord Name</StyledTableCell>
            <StyledTableCell>Date Started</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {isLoadingAttachedProperties ? (
          <></>
        ): (
          <>
          {attachedProperties
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((property) => (
              <StyledTableRow key={property.id}>
                <StyledTableCell>{property.related_property.rental_name}</StyledTableCell>
                <StyledTableCell>{property.related_property.related_landlord.business_name}</StyledTableCell>
                <StyledTableCell>{property.related_property.related_landlord.related_user.first_name} {property.related_property.related_landlord.related_user.last_name}</StyledTableCell>
              <StyledTableCell>{property.date_started}</StyledTableCell>
                <StyledTableCell>
                  <IconButton onClick={()=>handleNavigateUnits(property.related_property.id)}>
                    <VisibilityIcon sx={{ fontSize: "16px", marginRight: "5px" }}  />
                    <Typography color="blue">View Details</Typography>
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </>
        )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={6}
              count={attachedProperties.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
