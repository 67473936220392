import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';


export default function PropertManagerPopover({
	popperId,
	openPopper,
	anchorEl,
	handleClickOpen, 
	handleClosePopOver,
	details,
	handleClickOpenTenantDetails,
	handleNavigateTenantPayments,
	handleModalView,
	activeOccupancy,
	navigate
}) {
	const handleAction = (modalview) => {
		handleModalView(modalview);
		handleClickOpen();
		handleClosePopOver();
	};
	return (
		<div>
			<Popover
				id={popperId}
				open={openPopper}
				anchorEl={anchorEl}
				onClose={handleClosePopOver}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Stack
					sx={{
						padding: "10px",
						alignContent: "left",
					}}
				>
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("message")}>
						Send Message
					</Button>
					<Divider />
					<Button sx={{ color: "red", padding: "10px" }} onClick={() => handleAction("delete")}>
						Unassign Manager
					</Button>
					<Divider />
				</Stack>
			</Popover>
		</div>
	);
}