import { Box, styled } from "@mui/material";

export const FlexCenter = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const FlexStart = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
});

export const FlexEnd = styled(Box)({
  display: "flex",
  alignItems: "flex-end",
});

export const FlexColLeft = styled(Box)({
  display: "flex",
  justifyContent: "start",
  flexDirection: "column",
});

export const FlexColRight = styled(Box)({
  display: "flex",
  justifyContent: "end",
  flexDirection: "column",
});

export const FlexRowStart = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
});

export const FlexRowEnd = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "row",
});

export const FlexJustifyStart = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
});

export const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

export const FlexColCenter = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const FlexRowCenter = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});
