import * as React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { baseURL } from "services/API";
import { Grid, Box, Typography } from "@mui/material";
import TenantsTable from "components/Tables/TenantsTable";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import BasicSelect from "components/select";
import FormDialog from "components/modal";
import TenantsForm from "components/Forms/TenantsForm";
import DummyTable from "components/Tables/DummyTable";
import ProgressScale from "components/ProgressScale";
import { useGetRentalTenants, useRentals } from "services/hooks/rentals";
import { setRefreshCount, setTenantOccupancy } from "state";
import TopSnack from "components/TopSnack";
import TenantsPopover from "components/PopOvers/TenantsPopover";
import TenantMessageForm from "components/Forms/TenantMessageForm";
import TenantUpdateForm from "components/Forms/TenantUpdateForm";
import { useNavigate } from "react-router-dom";
import {FlexRowEnd } from "components/FlexBox/FlexBox";
import PropertManagerPopover from "components/PopOvers/PropertyManagerPopover";
import AssignPropertyForm from "components/Forms/AssignPropertiesForm";
import moment from "moment";
import PropertyManagersTable from "components/Tables/PropertyManagerTable";

export default function PropertyManagers() {
	const token = useSelector((state) => state.token);
	const id = useSelector((state) => state.property_id);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	const [rental, setRental] = React.useState("");
	const [unit, setUnit] = React.useState("");
	const [message, setMessage] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [openTenantDetails, setOpenTenantDetails] = React.useState(false);
	const [openSnack, setOpenSnack] = React.useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const [severity, setSeverity] = React.useState("");
	const { rentalTenants, isLoadingRentalTenants} = useGetRentalTenants();
	const [loadingTenantsError, setLoadingTenantsError] = React.useState("");
	const [loadingTenantDetails, setLoadingTenantDetails] = React.useState(true);
  	const [mangerId, setManagerId] = React.useState("");
  	const [openAssignmentForm, setOpenAssignmentForm] = React.useState(false);
	const [modal, setModal] = React.useState("");
	const [details, setDetails] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

	

	const handleClickOpen = () => {
		setLoadingTenantDetails(false);
		setLoadingTenantsError("");
		setOpen(true);
	};

	const handleClose = () => {
		setModal("");
		setOpen(false);
	};

	const handleClickOpenTenantDetails = () => {
		setOpenTenantDetails(true);
	};


	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack({ ...openSnack, open: false });
	};

	const handleClickPopOver = (event, row) => {
		setAnchorEl(event.currentTarget);
		setDetails(row)
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	const handleNavigateTenantPayments = (occupancy) => {
		dispatch(setTenantOccupancy(occupancy))
		navigate("/tenant-payments");
	}

	const handleManagerChange = (event) => {
		setManagerId(event.target.value);
	};

	const handleAssignmentFormSubmit = async () => {
		try {
		const response = await axios.post(`${baseURL}/admin/managers/properties`, {
			related_manager: mangerId,
			related_property: id,
			date_started: moment().format("YYYY-MM-DD"),
		});
		setOpenSnack({...openSnack, open: true});
		setSeverity("success");
		setMessage("Property Assigned Successfully");
		setOpenAssignmentForm(false);
		dispatch(setRefreshCount());
		} catch (err) {
		setOpenSnack({ ...openSnack, open: true });
		setSeverity("error");
		}
	};

	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	return (
		<Box m="20px">
			<Grid container spacing={2}>
				{isLoadingRentalTenants && <ProgressScale />}
				<Grid item xs={12}>
					
					<Grid container spacing={2}>
						<Grid item xs={7}>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
								<Typography variant="h4" fontWeight={"bold"}>Managers Attached</Typography>
							</Box>
						</Grid>
						<Grid item xs={5} >
							<FlexRowEnd>
								<Button variant="contained" onClick={()=> handleClickOpen()}> Assign Manager</Button>
							</FlexRowEnd>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingRentalTenants ? (
								<DummyTable />
							) : (
								<PropertyManagersTable
									setDetails={setDetails}
									rows={rentalTenants}
									isLoadingRentalTenants={isLoadingRentalTenants}
									handleClickPopOver={handleClickPopOver}
									id={popperId}
								/>
							)}
						</Grid>
					</Grid>
					<PropertManagerPopover
						popperId={popperId}
						openPopper={openPopper}
						anchorEl={anchorEl}
						handleClickOpen={handleClickOpen}
						handleClosePopOver={handleClosePopOver}
						handleClickOpenTenantDetails={handleClickOpenTenantDetails}
						handleNavigateTenantPayments={handleNavigateTenantPayments}
						details={details}
						handleModalView={handleModalView}
						navigate={navigate}
					/>

					{ modal === "message" ? (
						<FormDialog
							title={`Sending Message to ${details.related_tenant.first_name}`}
							enableActions={true}
							content={<TenantMessageForm />}
							open={open}
							handleClose={handleClose}
							buttonText="submit"
						/>
					) : modal === "delete" ? (
						<FormDialog
							title="Remove Manager"
							enableActions={true}
							open={open}
							content="Are you sure you want to unassign this manager from the property?"
							handleClose={handleClose}
							buttonText="Yes, Unassign Mananger"
						/>
					): (
						<FormDialog
							open={open}
							handleClose={handleClose}
							enableActions={true}
							content={
								<AssignPropertyForm
								handleManagerChange={handleManagerChange}
								property={details}
								/>
							}
							title={"Assign Property Manager"}
							handleSubmit={handleAssignmentFormSubmit}
							buttonText="submit"
						/>
					)}

					<TopSnack
						vertical={openSnack.vertical}
						horizontal={openSnack.horizontal}
						open={openSnack.open}
						handleCloseSnack={handleCloseSnack}
						message={message}
						severity={severity}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
