import { Box, Paper, MenuItem, Grid, TextField, Typography, Divider, Card, CardActions, CardContent,  } from "@mui/material";
import DoughnutChart from "components/Graphs/DoughnutChart";
import BarChart from "components/Graphs/BarChart";
import {  FlexColCenter} from "components/FlexBox/FlexBox";
import { currencyFormatter } from "utilities/currencyFormatter";
import { MdBalance } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { BsCart4 } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function PropertyDashboard({handleChange}) {
    return(
       <Box m="5px">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h3">Property Portfolio Dashboard</Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1} sx={{ margin: "0.5rem" }}>
                        <Grid item xs={12} md={8}>
                            <Grid container sx={{ display: "flex", justifyContent:"space-between",width:"100%" }}>
                                <Grid item xs={12} md={5} sx={{ display:"flex", flexDirection:"column", padding:"10px", width:"100%", margin:"0 1rem 0" }}>
                                    <FlexColCenter sx={{ padding:"1rem", width: "100%"}}>
                                        <Typography variant="h6" fontWeight="bold">Rent Collection Rate</Typography>
                                        <DoughnutChart />
                                    </FlexColCenter>
                                </Grid>
                                <Grid item xs={12} md={5} sx={{ display:"flex", flexDirection:"column", padding:"10px", width:"100%", margin:"0 1rem 0" }}>
                                    <FlexColCenter sx={{ padding:"1rem", width: "100%"}}>
                                        <Typography variant="h6" fontWeight="bold">Occupancy Rate</Typography>
                                        <DoughnutChart />
                                    </FlexColCenter>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} xs={12} md={4} sx={{ padding:"10px" }}>
                                    <Box sx={{ display:"flex", flexDirection:"column", padding:"10px", width:"100%", margin:"0 1rem 0" }}>
                                        <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                            <CardContent>
                                                <Box sx={{ display:"flex", flexDirection:"row" }} >
                                                    <Box sx={{ width:'70%' }}>
                                                        <Typography variant="h4" fontWeight="bold">Total Units</Typography>
                                                        {/* <Typography variant="overline"> from {newMessages.units} units</Typography> */}
                                                        <Typography variant="h2" fontWeight="bold">{6}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Link onClick={()=>handleChange("e", 2)}> 
                                                            <Typography variant="body1" >View Details &gt;</Typography>
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                        
                                        <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                            <CardContent>
                                                <Box sx={{ display:"flex", flexDirection:"row" }} >
                                                    <Box sx={{ width:'70%' }}>
                                                        <Typography variant="h4" fontWeight="bold">Current Tenants</Typography>
                                                        {/* <Typography variant="overline"> from {closedTickets.units} units</Typography> */}
                                                        <Typography variant="h2" fontWeight="bold">{5}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Link onClick={()=>handleChange("e", 3)}>
                                                            <Typography variant="body1" >View Details &gt;</Typography>
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                            <CardContent>
                                                <Box sx={{ display:"flex", flexDirection:"row" }} >
                                                    <Box sx={{ width:'70%' }}>
                                                        <Typography variant="h4" fontWeight="bold">Open Tickets</Typography>
                                                        <Typography variant="overline"> from {4} units</Typography>
                                                        <Typography variant="h2" fontWeight="bold">{6}</Typography>

                                                    </Box>
                                                    <Box>
                                                        <Link onClick={()=>handleChange("e", 5)}>
                                                            <Typography variant="body1" >View Details &gt;</Typography>
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{ margin: "0.5rem" }}>
                        <Grid item xs={null} md={8} >
                            <Paper sx={{ padding:"1rem", width: "100%"}}>
                                <Grid container xs={12}>
                                    <Grid item xs={12} sx={{ margin:"1rem" }}>
                                        <Typography variant="h6" fontWeight="bold">Revenue Overvue</Typography>
                                        <Typography variant="body2">Your property finance report for year 2024</Typography>
                                        <Divider/>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={8} sx={{ padding:"0.5rem" }} >
                                            <BarChart
                                                label = {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                                                data={[
                                                    {
                                                    label: "Income",
                                                    data: [65, 59, 80, 81, 56, 55, 40, 59, 80, 81, 56, 55, 40],
                                                    fill: false,
                                                    backgroundColor: "rgba(135, 211, 124)",
                                                    tension: 0.1,
                                                    },
                                                    {
                                                    label: "Expense",
                                                    data: [ 80, 81, 56, 55, 40, 80, 81, 65, 59, 40, 59, 56, 55],
                                                    fill: false,
                                                    backgroundColor: "rgba(30, 139, 195)", 
                                                    tension: 0.1,
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                        <Divider variant="middle" orientation="vertical"/>
                                        <Grid item xs={3.7} sx={{ padding:"0.5rem"}}>
                                            <FlexColCenter sx={{ display:"flex", flexDirection:"column", padding:"0.5rem",  }}>
                                                <Box sx={{ margin:"1rem", display:"flex", justifyContent:"space-between"}}>
                                                    <Typography sx={{ color: "#A05ACE", padding: "5px 5px 0 5px", borderRadius: "12px", marginRight:"1px", backgroundColor: "#ffebff"  }} variant="h2">
                                                        {<MdBalance/>}
                                                    </Typography>
                                                    <div>
                                                        <Typography variant="h5" fontWeight="bold">{currencyFormatter(parseFloat(22500000))}</Typography>
                                                        <Typography variant="body2">Total Balance</Typography>
                                                    </div>
                                                </Box>
                                                <Box sx={{ margin:"1rem", display:"flex", justifyContent:"space-between"}}>
                                                    <Typography sx={{ color: "green", padding: "5px 5px 0 5px", borderRadius: "12px", marginRight:"1px", backgroundColor: "rgba(135, 211, 124)" }} variant="h2">
                                                        {<GiCash/>}
                                                    </Typography>
                                                    <div>
                                                        <Typography variant="h5" fontWeight="bold">{currencyFormatter(parseFloat(22500000))}</Typography>
                                                        <Typography variant="body2">Total Income</Typography>
                                                    </div>
                                                </Box>
                                                <Box sx={{ margin:"1rem", display:"flex", justifyContent:"space-between"}}>
                                                    <Typography sx={{ color: "rgba(30, 139, 195)", padding: "5px 5px 0 5px", borderRadius: "12px", marginRight:"1px", backgroundColor: "lightblue"  }} variant="h2">
                                                        {<BsCart4/>}
                                                    </Typography>
                                                    <div>
                                                        <Typography variant="h5">{currencyFormatter(parseFloat(22500000))}</Typography>
                                                        <Typography variant="body2">Total Expense</Typography>
                                                    </div>
                                                </Box>
                                            </FlexColCenter>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid container spacing={2} xs={12} md={4}  sx={{ padding:"10px" }} >
                           <Box sx={{ display:"flex", flexDirection:"column", padding:"10px", width:"100%", margin:"0 1rem 0" }}>
                                <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                    <CardContent>
                                        <Box sx={{ display:"flex", flexDirection:"row" }} >
                                            <Box sx={{ width:'70%' }}>
                                                <Typography variant="h4" fontWeight="bold">Rent Arrears</Typography>
                                                <Typography variant="overline"> from 15 units</Typography>
                                                <Typography variant="h3" fontWeight="bold">{currencyFormatter(parseFloat(1500000))}</Typography>
                                                <Typography variant="body2"> as of the October</Typography>
                                            </Box>
                                            <Box>
                                                <Link>
                                                    <Typography variant="body1" >View Details &gt;</Typography>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                    <CardContent>
                                        <Box sx={{ display:"flex", flexDirection:"row" }} >
                                            <Box sx={{ width:'70%' }}>
                                                <Typography variant="h4" fontWeight="bold">Rent Collected</Typography>
                                                <Typography variant="overline"> from 8 units</Typography>
                                                <Typography variant="h3" fontWeight="bold">{currencyFormatter(parseFloat(3750000))}</Typography>
                                                <Typography variant="body2"> out of {currencyFormatter(parseFloat(5050000))}</Typography>
                                            </Box>
                                            <Box>
                                                <Link>
                                                    <Typography variant="body1" >View Details &gt;</Typography>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
	    </Box>
    )
}