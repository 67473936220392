import  React, { useState } from 'react';
import {Button, MenuItem} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import FlexBetween from 'components/FlexBetween';
import axios from 'axios';
import { baseURL } from 'services/API';
import { useLandlordProperties } from 'services/hooks/rentals';
import { useSelector } from 'react-redux';


export default function EditExpense({ open,handleClose,handleSubmit,title,  text, handleInputChange,isSubmittingData,expenseTypes, formData, errors}) {
    const landlord = useSelector((state) => state.landloard);
    const {landlordProperties,loading, error} = useLandlordProperties(landlord.id);
    const [selectedExpenseDetails, setSeletedExpenseDetails] = React.useState([]);
  const fetchData = async (type) => {
    try {
      const response = await axios.get(
        `${baseURL}/admin/expense_details?type_id=${type}`
      );
      setSeletedExpenseDetails(response.data.data);
    } catch (e) {
      return;
    }
    return true;
  };

  if (loading) {
    return <div>Loading rentals...</div>;
  }

  if (error) {
    return <div>Error loading rentals</div>;
  }
 

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontSize: 20 }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: 15, marginBottom: 2 }}>
            {text}
          </DialogContentText>

          <TextField
            name="related_landlord_rental"
            fullWidth
            value={formData.related_landlord_rental.id}
            error={Boolean(errors.related_landlord_rental)}
            helperText={errors.related_landlord_rental}
            onChange={handleInputChange}
            select
            label="Landlord Rental"
          >
            {landlordProperties.map((rental) => (
              <MenuItem key={rental.id}  value={rental.id}>{rental.rental_name}</MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            variant="outlined"
            name="amount"
            value={formData.amount}
            error={Boolean(errors.amount)}
            helperText={errors.amount}
            onChange={handleInputChange}
          />
          <FlexBetween>
            <TextField
            margin="dense"
            label="Expense Type"
            select
            sx={{ width: "49%" }}
            variant="outlined"
            name="expense_type"
            error={Boolean(errors.expense_type)}
            helperText={errors.expense_type}
            value={formData.expense_type}
            onChange={(e, value) => {
              handleInputChange(e);
              fetchData(e.target.value);
            }}
          >
            {expenseTypes.map((item, index) => {
              return (
                <MenuItem key={item.id} selected={formData.expense_type===item.id} value={item.id}>{item.expense_type_name}</MenuItem>
              );
            })}
          </TextField>

          <TextField
            margin="dense"
            label="Expense Category"
            type="text"
            sx={{ width: "49%" }}
            select
            variant="outlined"
            name="expense_category"
            value={formData.expense_category}
            error={Boolean(errors.expense_category)}
            helperText={errors.expense_category}
            onChange={handleInputChange}
          >
            {selectedExpenseDetails.map((item, index) => {
              return (
                <MenuItem key={item.id} selected={formData.expense_category===item.id} value={item.id}>{item.expense_detail}</MenuItem>
              );
            })}
          </TextField>

          </FlexBetween>
          
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            name="description"
            value={formData.description}
            error={Boolean(errors.description)}
            helperText={errors.description}
            onChange={handleInputChange}
          />

          {/* Form Fields */}
          {/* <Box component="form" noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box display="flex" gap={2}>
              <Box sx={{ flex: 1 }}>
                <InputLabel htmlFor="description" sx={{ fontSize: 14 }}>Description</InputLabel>
                <TextField
                  id="description"
                  variant="outlined"
                  fullWidth
                  defaultValue={description}
                  onChange={(e) => handleInputChange(e, 'description')}
                  InputLabelProps={{ shrink: false }} // Prevent label from shrinking
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <InputLabel htmlFor="rental_name" sx={{ fontSize: 14 }}>Rental Name</InputLabel>
                <TextField
                  id="rentalName"
                  variant="outlined"
                  fullWidth
                  defaultValue={rentalName}
                  onChange={(e) => handleInputChange(e, 'rental_name')}
                  InputLabelProps={{ shrink: false }} // Prevent label from shrinking
                />
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="Amount" sx={{ fontSize: 14 }}>Amount</InputLabel>
              <TextField
                id="Amount"
                variant="outlined"
                fullWidth
                defaultValue={Amount}
                onChange={(e) => handleInputChange(e, 'amount')}
                InputLabelProps={{ shrink: false }} // Prevent label from shrinking
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="Date" sx={{ fontSize: 14 }}>Date</InputLabel>
              <TextField
                id="Date"
                variant="outlined"
                fullWidth
                defaultValue={Date}
                onChange={(e) => handleInputChange(e, 'Date')}
                InputLabelProps={{ shrink: false }} // Prevent label from shrinking
              />
            </Box>
          </Box> */}
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button variant="outlined" onClick={handleClose} sx={{ color: "red", fontSize: 14 }}>
            Cancel
          </Button>
          {isSubmittingData ? (
            <LoadingButton
              variant="filled"
              loading
              startIcon={<SaveIcon />}
              loadingPosition="start"
              sx={{ color: "#188c0b", fontSize: 14 }}
            >
              Editing Data ...
            </LoadingButton>
          ) : (
            <Button variant="outlined" onClick={handleSubmit} sx={{ color: "#188c0b", fontSize: 14 }}>
              Edit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
