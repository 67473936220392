import React from "react";
import { TextField, Grid } from "@mui/material";

const BrokerForm = ({ newBroker, handleChange, errors }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          margin="dense"
          label="Full Name"
          type="text"
          fullWidth
          name="broker_name"
          value={newBroker.broker_name}
          onChange={handleChange}
          error={errors.broker_name}
          helperText={errors.broker_name}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          label="Phone Number"
          type="text"
          fullWidth
          name="broker_contact"
          value={newBroker.broker_contact}
          onChange={handleChange}
          error={errors.broker_contact}
          helperText={errors.broker_contact}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          label="Location"
          type="text"
          fullWidth
          name="broker_location"
          value={newBroker.broker_location}
          onChange={handleChange}
          error={errors.broker_location}
          helperText={errors.broker_location}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          margin="dense"
          label="National Identification Number (NIN)"
          type="text"
          fullWidth
          name="broker_NIN"
          value={newBroker.broker_NIN}
          onChange={handleChange}
          error={errors.broker_NIN}
          helperText={errors.broker_NIN}
        />
      </Grid>
    </Grid>
  );
};

export default BrokerForm;
