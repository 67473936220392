// src/scenes/Tenants/TenantDetails.jsx

import React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import TenantDetailsTable from "components/Tables/TenantDetailsTable"; // Import TenantDetailsTable

const TenantDetails = () => {
  const location = useLocation();
  const { tenant } = location.state || {}; // Get tenant data from state

  const [page, setPage] = React.useState(0);
  const [filterType, setFilterType] = React.useState(""); // Initial state should be empty
  const rowsPerPage = 5; // Number of rows per page

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  if (!tenant) {
    return <div>No tenant data available</div>;
  }

  // Define paymentData directly in TenantDetails if needed
  const paymentData = [
    { id: 1, date: "2024-01-15", amount: 1200, method: "Credit Card" },
    { id: 2, date: "2024-02-15", amount: 1300, method: "Bank Transfer" },
    { id: 3, date: "2024-03-15", amount: 1400, method: "Credit Card" },
  ];

  const totalAmountPaid = paymentData.reduce(
    (acc, payment) => acc + payment.amount,
    0
  );
  const creditScore = 750; // Example credit score

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1, paddingLeft: "260px", padding: "80px" }}>
          <Box sx={{ p: 3 }}>
            {/* Grid Layout for Cards */}
            <Grid container spacing={3}>
              {/* Cards for Total Amount Paid and Credit Score */}
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    width: "60%", // Reduce width
                    backgroundColor: "#d1e7fd", // Light blue
                    boxShadow: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6">Total Amount Paid</Typography>
                    <Typography variant="h4">${totalAmountPaid}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    width: "60%", // Reduce width
                    backgroundColor: "#fce5cd", // Peach puff
                    boxShadow: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingRight: "0px",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6">Credit Score</Typography>
                    <Typography variant="h4">{creditScore}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Add space between the cards and the filter */}
            <Box sx={{ mt: 5 }}>
              <FormControl
                variant="outlined"
                size="small"
                sx={{ flexGrow: 1, height: 56 }}
              >
                <InputLabel id="filter-by-label">Filter By</InputLabel>
                <Select
                  labelId="filter-by-label"
                  value={filterType}
                  onChange={handleFilterTypeChange}
                  label="Filter By"
                  sx={{ width: 300, fontSize: 14, height: "100%" }} // Ensure Select takes full height
                >
                  <MenuItem value="" disabled>
                    Select filter
                  </MenuItem>{" "}
                  {/* Placeholder */}
                  <MenuItem value="PaymentDate">Payment Date</MenuItem>
                  <MenuItem value="Amount">Amount</MenuItem>
                  <MenuItem value="PaymentMethod">Payment Method</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/* Add space between the filter and the table */}
            <Box sx={{ mt: 5 }}>
              <TenantDetailsTable
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                paymentData={paymentData} // Pass paymentData to TenantDetailsTable
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TenantDetails;
