import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin, setManagerDetails } from "state";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import { baseURL } from "services/API";
import LinearProgress from '@mui/material/LinearProgress';

const registerSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
  location: yup.string().required("required"),
  occupation: yup.string().required("required"),
  picture: yup.string().required("required"),
});

const loginSchema = yup.object().shape({
  username: yup.string().required("required"),
  password: yup.string().required("required"),
});

const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  location: "",
  occupation: "",
  picture: "",
};

const initialValuesLogin = {
  username: "",
  password: "",
};

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const isRegister = pageType === "register";
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")

  const register = async (values, onSubmitProps) => {
    // this allows us to send form info with image
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    formData.append("picturePath", values.picture.name);

    const savedUserResponse = await fetch(
      "http://localhost:3001/auth/register",
      {
        method: "POST",
        body: formData,
      }
    );
    const savedUser = await savedUserResponse.json();
    onSubmitProps.resetForm();

    if (savedUser) {
      setPageType("login");
    }
  };

  const login = async (values, onSubmitProps) => {
    const loggedInResponse = await fetch(`${baseURL}/accounts/authenticate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });
    setIsLoading(true)
    const loggedIn = await loggedInResponse.json();

    onSubmitProps.resetForm();
    if (loggedIn.status===200) {
      dispatch(setLogin({
          user: loggedIn.data.user_details,
          token: loggedIn.data.token.token,
          admin: loggedIn.data.admin
        })
      );
      if(loggedIn.data.admin === 0){
        dispatch(setManagerDetails(loggedIn.data.manager))
      }
      setIsLoading(false)
      navigate("/home");
    }else{
      setIsLoading(false)
      setError("Invalid Username or Password, Please try again")
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isLogin) await login(values, onSubmitProps);
    if (isRegister) await register(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
    

            <TextField
              label="Username"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="username"
              error={Boolean(touched.username) && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              sx={{ gridColumn: "span 4", borderRadius: "2rem"}}
            />
            <TextField
              label="Password"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: "span 4", borderRadius: "2rem"}}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            {isLoading ? (
              <LinearProgress 
              color="warning"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: "#141b2d",
                borderRadius: "2rem",
              }}
              />
            ) : (
              <Button
                fullWidth
                type="submit"
                sx={{
                  m: "2rem 0",
                  p: "1rem",
                  borderRadius: "2rem",
                  backgroundColor: "#141b2d",
                  color: "#FCB200",
                  "&:hover": { color: "#141b2d" },
                }}
              >
                {isLogin ? "LOGIN" : "REGISTER"}
              </Button>
            )}
            
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;
