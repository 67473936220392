import React from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "state";
import CustomTab from "components/Tabs/Tab";
import PropertiesManaged from "scenes/Management/PropertiesManaged.jsx/index.jsx";
import Payments from "scenes/Payments";




const ManagementTab = () => {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['managementTab']) );
    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setCurrentTab({ name: "managementTab", tab: newValue }));
    };

    const tabComponents = [
      { Component: <PropertiesManaged />, label: "Properties Managed", index: 0 },
      { Component: <Payments/>, label: "Payments", index: 1 },
      { Component: <></>, label: "Bookings", index: 2 },
      { Component: <></>, label: "Landlords Attached", index: 3 },
      { Component: <></>, label: "Tenants Attached", index: 4 },
      { Component: <></>, label: "Reporting", index: 5 },
    ]; 


    return (
        <Box>
            <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/> 
        </Box>
    )

}

export default ManagementTab;