import React from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "state";
import Managers from "scenes/Management/Managers/Managers";
import Brokers from "scenes/Brokers";
import CustomTab from "components/Tabs/Tab";




const SettingsTab = () => {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['settingsTab']) );
    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setCurrentTab({ name: "settingsTab", tab: newValue }));
    };

    const tabComponents = [
      { Component: <Managers />, label: "MANAGER SETTINGS", index: 0 },
      { Component: <Brokers/>, label: "BROKER SETTINGS", index: 1 },
    ]; 


    return (
        <Box>
            <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/> 
        </Box>
    )

}

export default SettingsTab;