import React, { useState } from "react";
import {Paper, Table, TableBody, TableContainer, TableHead, TableRow, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, TablePagination, TableFooter} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from "@mui/icons-material/Message";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLandloard } from "state";
import { baseURL } from "services/API";
import EditLandlord from "components/LandlordModals/EditLandlord";
import axios from "axios";
import DeleteLandlord from "components/LandlordModals/DeleteLandlord";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"; 

// LandlordsTable component
const InvisibleLandlordsTable = ({data, handleMenuClick}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLandlord, setSelectedLandlord] = React.useState(null);
  const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  handleMenuClick = (event, landlord) => {
    setAnchorEl(event.currentTarget);
    setSelectedLandlord(landlord);
    dispatch(setLandloard(landlord)); 
  };

  
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedLandlord(null);
  };

  const handleViewClick = () => {
    handleMenuClose();
    // if (selectedLandlord) {
    //   navigate(`/landlordDetails`, {state : selectedLandlord});
    // }
  };

  const handleSendMessage = async () => {
    if (selectedLandlord && message) {
      setMessageDialogOpen(false);
      setMessage("");
    }
  };


  const handleDialogClose = () => {
    setMessageDialogOpen(false);
    setSelectedLandlord(null);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Username</StyledTableCell>
              <StyledTableCell>Business Name</StyledTableCell>
              <StyledTableCell>Pay Day</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((landlord, index) => (
                <StyledTableRow key={landlord.id}>
                  <StyledTableCell>
                    {index+1}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.phone_number}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.first_name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.last_name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.username}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.business_name}
                  </StyledTableCell>
                  <StyledTableCell>{landlord.pay_day}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e, landlord)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(
                        anchorEl && selectedLandlord?.id === landlord.id
                      )}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleViewClick}>
                        <VisibilityIcon fontSize="small" />
                        Request Visibility
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, { label: "All", value: -1 }]}
                    colSpan={7}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      
      <Dialog open={messageDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Send Message</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Message"
            type="text"
            fullWidth
            variant="standard"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSendMessage}>Send</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default InvisibleLandlordsTable;
